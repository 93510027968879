import cache, {APP_CACHE} from "../../common/util/cacheManager";
import CONSTANTS from "../../common/util/constants";
import AllSymptomList, {FindAllSymptomList} from "../../symptom/config/AllSymptomList";
import AllowServiceList from "./AllowServiceList";
import GenerateServiceType from "./ServiceType";
import selectDeepLinkSymptom from "../../symptom/SelectDeepLinkSymptom";

const nbnServiceList = ['FTTP','FTTN','FTTB','FTTC','HFC','FW','Wireless'];
const UNITI_SERVICE = {
    ACCESS_TYPE : "Uniti"
}

export function getServiceType(productName, techType = ''){
    // if(productName == "Internet Service" && techType == "ADSL")
    //     return "ADSL_INTERNET"
    switch(productName) {
        case "Voice Service":
        case "Telephone Line Service":
            return 'HOMEPHONE';
        case "Internet Service":
        case "NBN Fibre Access":
            return "INTERNET";
        case "Applications & Entertainment Services":
            return "EMAIL";
        case "Cable Internet Access Type":
            return "CABLE";
        case "Mobile Service":
            return "MOBILE";
        case "Pre-Paid Mobile Service":
            return "MOBILE";
        case "Mobile SIM":
            return "MOBILE";
        default:
            return "NONE"
    }
}

export function getServiceTypeName(productName) {
    const serviceType = getServiceType(productName);
    switch(serviceType) {
        case "HOMEPHONE":
            return "phone";
        case "ADSL_INTERNET":
        case "INTERNET":
            return "internet";
        case "EMAIL":
            return "email";
        case "CABLE":
            return "cable";
        /* istanbul ignore next*/
        case "ADSL":
            /* istanbul ignore next*/
            return "adsl";
        case "MOBILE":
            return "mobile";
        default:
            return "";
    }
}

export function formatPhoneNumber(serviceId) {
  if(serviceId.length > 6 && serviceId.length <= 10){
    return serviceId.substring(0,2) + " " + serviceId.substring(2,6) + " " + serviceId.substring(6)
  } else {
    return serviceId
  }
}

export function formatPhoneNumberForMobile(serviceId) {
    if(serviceId.length > 10 && serviceId.startsWith('61'))
    {
        serviceId=serviceId.replace('61','0');
    }
    if(serviceId.length > 6 && serviceId.length <= 10){
        return serviceId.substring(0,4) + " " + serviceId.substring(4,7) + " " + serviceId.substring(7)
    } else {
        return serviceId
    }
}

export  function filterIssues(issues,deeplinkData) {
    const sym=deeplinkData.symptom?.toUpperCase();
    return issues.filter((a) => {return a.name === CONSTANTS.DEEPLINK_MAPPING[sym]});
}
export function isDeeplinkMVP3(selectService,deeplinkData) {
    if(deeplinkData?.symptom=="speed") {
        deeplinkData.symptom = selectService.label.replace(/\-/g,'').replace(/\ /g,"")+"_"
            +deeplinkData.symptom.toUpperCase();
        cache.saveProperty(APP_CACHE.MVP3DEEPLINK, deeplinkData);
    }else if(deeplinkData?.symptom=="cannotconnect"){
        deeplinkData.symptom = selectService.label.replaceAll("-","").replaceAll(" ","")+"_"
            +deeplinkData.symptom.toUpperCase();
        cache.saveProperty(APP_CACHE.MVP3DEEPLINK, deeplinkData);
    }
    return !!deeplinkData?.symptom;
}
export  function filterIssuesMobile(deeplinkData) {
    const sym=deeplinkData.symptom?.toUpperCase();
    return FindAllSymptomList(CONSTANTS.DEEPLINK_MAPPING[sym])//issues.filter((a)=>{return a.name===CONSTANTS.DEEPLINK_MAPPING[sym]});
}

export function isNbnService(serviceType) {
    return (nbnServiceList.includes(serviceType))
}

export function isNonAssistedService(accessType) {
   return CONSTANTS.NON_ASSISTED_SERVICES_ACCESS_TYPES.includes(accessType)
}
export function getDeepLink(){
    return (sessionStorage && sessionStorage.hasOwnProperty("deeplink"))?JSON.parse(sessionStorage.getItem("deeplink")):undefined;
}

export function redirectToSpecifiedLocation(history, location = "/smart-troubleshooting/transfertoagent", state ){
    const trackingParameter =  getDeepLink()?.tc;
    const path = state ? {pathname:location, state : state} : {pathname: location}
    if(trackingParameter && trackingParameter !=null){
        history.push({...path, search:"?tc="+trackingParameter});
    }else{
        history.push(path);
    }
}

export function cleanDeepLink() {
    if (sessionStorage && sessionStorage.hasOwnProperty("deeplink") && !JSON.parse(sessionStorage.getItem("deeplink"))?.tc) {
        sessionStorage.removeItem("deeplink");
    }
}

export const DEEPLINK_TECHTYPE = {
    MOBILE: 'mobile',
    BROADBAND: 'broadband',
    HOMEPHONE: 'homephone',
    EMAIL: 'email',
    UNKNOWN: 'unknown',
}

export const redirectToMTW = (techtype) => {
    if(techtype === DEEPLINK_TECHTYPE.BROADBAND)
        window.location = window.metadata.app.myTRedirect + "get-help-home-internet";
    if(techtype === DEEPLINK_TECHTYPE.HOMEPHONE)
        window.location = window.metadata.app.myTRedirect + "get-help-home-phone";
    if(techtype === DEEPLINK_TECHTYPE.MOBILE)
        window.location = window.metadata.app.myTRedirect + "mobile-connection-issues";
    if (techtype === DEEPLINK_TECHTYPE.EMAIL)
        window.location = window.metadata.app.myTRedirect + "get-help-email";
}
const knownServices = GenerateServiceType(CONSTANTS);
export async function filterServices(services, props){

    let deepLink =  getDeepLink();
    let serviceType= deepLink?.serviceType ? redirectToMTW(deepLink?.serviceType) : "";
    let techtype= deepLink?.techtype ? redirectToMTW(deepLink?.techtype) : "";

    if(deepLink?.symptom){
        cache.saveProperty(APP_CACHE.MVP3DEEPLINK,deepLink);
    }
    if(deepLink?.token && deepLink?.signature) {
        return filterByServiceId(props, services, deepLink);
    }
    else if(services && serviceType){
        const allowServices = services.filter(_service=> {
            const serviceDetails = knownServices[_service.productName];
            _service.label = serviceDetails?.title;
            switch (serviceDetails && serviceDetails.title) {
                case AllowServiceList.MOBILE_TITLE:
                case AllowServiceList.PREPAID_TITLE:
                    return (techtype === "mobile");
                case AllowServiceList.EMAIL_TITLE:
                    return (techtype === "email");
                case AllowServiceList.INTERNET_TITLE:
                    return (techtype === "broadband");
                case AllowServiceList.PHONE_TITLE:
                    return (techtype === "homephone");
                default:
                    return false;
            }
        });
        if(allowServices && allowServices.length>0){
            return allowServices;
        }
    }

    return services;
}

export async function filterFaultIncidents(props) {
    let faultIncidentList = cache.getProperty(APP_CACHE.FAULT_SERVICES);
    let deepLink = getDeepLink();
    let paramType = deepLink?.serviceType ? deepLink.serviceType : deepLink?.techtype;
    let symptomType = deepLink?.symptom?.toLowerCase();
    if(deepLink?.token && deepLink?.signature){
        return filterByServiceId(props, faultIncidentList, deepLink, true);
    }
    else if(paramType)
        return faultIncidentList.filter((incident) => incident.serviceType.toLowerCase() === paramType);
    else if(symptomType === "cannotconnect"){
        return null;
    }
    else
        return faultIncidentList
}

export async function filterByServiceId(props, dataToFilter, deepLink, isFilterIncident) {
    let decryptedTokenService = cache.getProperty(APP_CACHE.DECRYPTED_TOKEN_SERVICE);
    if (!decryptedTokenService?.data?.serviceId) {
        decryptedTokenService = await selectDeepLinkSymptom.fetchDataFromDeepLink(deepLink);
        cache.saveProperty(APP_CACHE.DECRYPTED_TOKEN_SERVICE, decryptedTokenService);
    }

    let filteredData = [];
    if(decryptedTokenService?.data && decryptedTokenService?.data?.serviceId) {
        if (isFilterIncident) {
            filteredData = dataToFilter.filter(incident => incident?.technologyType !== "EMAIL" && incident.serviceId === decryptedTokenService.data.serviceId)
        }
        else {
            filteredData = dataToFilter.filter((ser) =>  ser.serviceId === decryptedTokenService.data.serviceId && ser?.technologyType !== "EMAIL");
            filteredData = (filteredData && filteredData.length === 0) ? dataToFilter : filteredData
        }
        return filteredData;
    }
    else if(decryptedTokenService?.data?.type === "FAULTTRACKER") {
        redirectToSpecifiedLocation(props.history,'/smart-troubleshooting/deeplink/faulttracker');
        return null;
    }
    else{
        return dataToFilter;
    }
}

export function getMaskedServiceId(serviceId, serviceType){
    if(serviceId && serviceId.length === 0){
        return '';
    }
    if([AllowServiceList.EMAIL_TITLE, AllowServiceList.INTERNET_TITLE].includes(serviceType) ){
        const [name, domain] = serviceId.split('@');
        return name.substr(0, 2) + 'xxxx@' + domain;
    }
    if([AllowServiceList.MOBILE_TITLE, AllowServiceList.PHONE_TITLE].includes(serviceType)){
        return serviceId.substring(0,2)+'xxxx';
    }

    return '';
}

export function saveIncidentDetails(incident) {
    const precheckObject = incident?.diagnostics ? Array.isArray( incident.diagnostics) && incident.diagnostics.filter((test)=>{
        return test.name? test.name === "precheck": false;
    }) : [];
    const drcCode = precheckObject.length>0&&precheckObject[0].drcCode?precheckObject[0].drcCode:"";
    const diagRes = (precheckObject.length>0 && precheckObject[0].status==="completed")?precheckObject[0].outcome:"INCONCLUSIVE";
    const selectedIssue = incident.symptom && AllSymptomList(incident.symptom)

    const diagResult={
        diagnosticRes: diagRes,
        diagnosticResultCode: drcCode,
        outageRes: "INCONCLUSIVE"
    }

    cache.saveProperty(APP_CACHE.DIAGNOSTIC_RESULTS, diagResult);
    cache.saveProperty(APP_CACHE.SELECTED_SERVICE, incident.service);
    cache.saveProperty(APP_CACHE.SELECTED_ISSUE, selectedIssue);
    cache.saveProperty(APP_CACHE.CUSTOMER_HAS_HIGH_SPEED_TIER, incident?.service?.hstCustomer);
    cache.saveProperty(APP_CACHE.SNOW_CORRELATION_ID, incident.correlationId);
    cache.saveProperty(APP_CACHE.IS_WORKFLOW_RESUMED, "resumed");
    cache.saveProperty(APP_CACHE.INCIDENT_NUMBER, incident.incidentNumber);

}
