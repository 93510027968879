import React from "react";

import alarmIcon from "../images/import/alarm";
import apps247Icon from "../images/import/apps-24x7";
import callerIdIcon from "../images/import/icon-caller-id";
import controlsAlertNotificationIcon from "../images/import/controls-alert-notification";
import coverageIcon from "../images/import/coverage";
import DeviceProtectIcon from "../images/import/DeviceProtect";
import emailIcon from "../images/import/email";
import greaterReliabilityIcon from "../images/import/greater-reliability";
import helpSupportIcon from "../images/import/help-support";
import cannotCallIcon from "../images/import/icon-cannot-call";
import cannotEmailIcon from "../images/import/icon-cannot-email";
import cannotMakeCallIcon from "../images/import/icon-cannot-make-call";
import cannotReceiveCallIcon from "../images/import/icon-cannot-receive-call";
import cannotReceiveEmailIcon from "../images/import/icon-cannot-receive-email";
import cannotReceiveSmsIcon from "../images/import/icon-cannot-receive-sms";
import cannotSendEmailIcon from "../images/import/icon-cannot-send-email";
import cannotSendSmsIcon from "../images/import/icon-cannot-send-sms";
import cannotSmsIcon from "../images/import/icon-cannot-sms";
import internationalDestinationIcon from "../images/import/international-destination";
import menuIcon from "../images/import/menu";
import messageBankIcon from "../images/import/MessageBank";
import mobileIcon from "../images/import/mobile";
import mobilePrepaidIcon from "../images/import/mobile-prepaid";
import modemIcon from "../images/import/modem";
import newsIcon from "../images/import/news";
import number1Icon from "../images/import/number-1";
import productInternationalRoamingIcon from "../images/import/product-international-roaming";
import productPhoneIcon from "../images/import/product-phone";
import rechargeIcon from "../images/import/recharge";
import settingsIcon from "../images/import/settings";
import simCardIcon from "../images/import/sim-card";
import socialThumbsDownIcon from "../images/import/social-thumbs-down";
import starIcon from "../images/import/star";
import textIcon from "../images/import/text";
import timeIcon from "../images/import/time";
import transferPlanIcon from "../images/import/transfer-plan";
import wirelessAccessPointIcon from "../images/import/wireless-access-point";
import gaming from "../images/import/gaming";
import iconModem32 from "../images/import/iconModem32";
import iconHelp from "../images/import/iconHelp";
import Installation24 from "../images/import/Installation24";
import iconSettings24 from "../images/import/iconSettings24";
import iconNetworkSpeed32 from "../images/import/iconNetworkSpeed32";
import iconEmail from "../images/import/icon-email";


export default {
    alarmIcon,
    apps247Icon,
    callerIdIcon,
    controlsAlertNotificationIcon,
    coverageIcon,
    DeviceProtectIcon,
    emailIcon,
    greaterReliabilityIcon,
    helpSupportIcon,
    cannotCallIcon,
    cannotEmailIcon,
    cannotMakeCallIcon,
    cannotReceiveCallIcon,
    cannotReceiveEmailIcon,
    cannotReceiveSmsIcon,
    cannotSendEmailIcon,
    cannotSendSmsIcon,
    cannotSmsIcon,
    internationalDestinationIcon,
    menuIcon,
    messageBankIcon,
    mobileIcon,
    mobilePrepaidIcon,
    modemIcon,
    newsIcon,
    number1Icon,
    productInternationalRoamingIcon,
    productPhoneIcon,
    rechargeIcon,
    settingsIcon,
    simCardIcon,
    socialThumbsDownIcon,
    starIcon,
    textIcon,
    timeIcon,
    transferPlanIcon,
    gaming,
    iconModem32,
    iconHelp,
    Installation24,
    iconSettings24,
    iconNetworkSpeed32,
    wirelessAccessPointIcon,
    iconEmail
};
