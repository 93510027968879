export class AppAnalytics {
    constructor() {
        this.analyticsStream = [];
        this.isCheckingOmniture = "not-loaded";
        try {
            if (localStorage.hasOwnProperty("analytics-stream")) {
                this.analyticsStream = JSON.parse(localStorage.getItem("analytics-stream"));
                localStorage.removeItem("analytics-stream");
            }
        } catch (e) {
            localStorage.removeItem("analytics-stream");
        }
        this.initiateDigitalData();
        //this.processStream();
        this.OMNITURE_EVENT_TYPE = {
            PAGEINFO : "pageInfo",
            EVENT: "eventInfo",
            PAGE: "page",
            PAGENAME: "pageName"

        };
    }
    getEnv(){
        const env = window.metadata.app.environment;
        return env === "prod" ? "production": env;
    }
    getSystemEnv (){
        const userAgent = navigator.userAgent.toLowerCase();
        if(userAgent.indexOf('android') > -1){
            return "Android";
        }else if(userAgent.indexOf('iphone') > -1){
            return "iOS";
        }else{
            return  "desktop";
        }
    }

    /* istanbul ignore next */
    initiateDigitalData() {
        window['digitalData'] = {
            user:[],
            page: {
                pageInfo:{
                    pageName:'selfcare',
                    destinationURL:window.location.href,
                    referringURL:document.referrer,
                    sysEnv:this.getSystemEnv(),
                    deployEnv: this.getEnv()
                },
                category:{
                    primaryCategory:'smart-troubleshooting',
                    secondaryCategory:'fixTelstra',
                    pageType: 'GetHelp | SmartHelp'
                },
                attributes:{
                    division:'TD',
                    subDivision:'TR',
                    subDivisionUnit:'TR'
                }
            },
            product: [],
            event: [{
                eventInfo: {
                    eventAction: "", // static value
                    eventType: "", // static value
                    eventCategory: "", // static value
                    eventName:""
                },
                attributes: {}
            }]
        };
    }


    send(data) {
        this.analyticsStream.push(data);
        this.processStream();
    }

    processStream() {
        if (this.analyticsStream.length > 0) {
            /* istanbul ignore next */
            while (this.analyticsStream.length > 0) {
                var _currentStreamData = this.analyticsStream.shift();

                if (this.getEventType(_currentStreamData).omniture === this.OMNITURE_EVENT_TYPE.PAGE)
                {
                    this.setDigitalData();
                    window.digitalData.page.pageInfo["pageName"] = this.formatPageData(_currentStreamData).omniture;
                }
                else if (this.getEventType(_currentStreamData).omniture === this.OMNITURE_EVENT_TYPE.PAGENAME)
                {
                    this.setDigitalData();
                    window.digitalData.page.pageInfo["pageName"] = this.formatPageData(_currentStreamData).omniture;
                }
                else if (this.getEventType(_currentStreamData).omniture === this.OMNITURE_EVENT_TYPE.PAGEINFO)
                {
                    this.setDigitalData();
                    window.digitalData.page.pageInfo["pageName"] =  this.formatPageData(_currentStreamData).omniture;
                    for (var _mainData in _currentStreamData.data) {
                        window.digitalData.page.pageInfo[_mainData] = _currentStreamData.data[_mainData];
                    }
                }
                else if(this.getEventType(_currentStreamData).omniture === this.OMNITURE_EVENT_TYPE.EVENT)
                {
                    this.setDigitalData();
                    window.digitalData.event.push({
                        eventInfo: {
                            eventAction: _currentStreamData.data.eventAction, // static value
                            eventType:  _currentStreamData.data.eventType, // static value
                            eventCategory: _currentStreamData.data.eventCategory, // static value
                            eventName:_currentStreamData.data.eventName
                        },
                        attributes: {}
                    });
                }else if (this.getEventType(_currentStreamData).omniture === "event") {//@deprecated
                    for (var _mainData in _currentStreamData.data) {
                        window.digitalData.page.pageInfo[_mainData] = _currentStreamData.data[_mainData];
                    }
                }
                else if (this.getEventType(_currentStreamData).omniture === "category")
               {
                    this.setDigitalData();
                    window.digitalData.page.pageInfo["pageName"] = this.formatPageData(_currentStreamData).omniture;
                }
                else if(this.getEventType(_currentStreamData).omniture === "user")
                {
                    window.digitalData.user.push(_currentStreamData.data);
                }
            }
        }

        if (this.isCheckingOmniture !== "loaded") {
            localStorage.setItem("analytics-stream", JSON.stringify(this.analyticsStream));
        }
    }

    setDigitalData() {
        window.digitalData.page.pageInfo.section = "smart-troubleshooting";
        window.digitalData.page.category["primaryCategory"] = "smart-troubleshooting";
        window.digitalData.page.category["secondaryCategory"] = "fixTelstra";
        window.digitalData.page.category["pageType"] = "GetHelp | SmartHelp";
        window.digitalData.page.pageInfo["deployEnv"] = this.getEnv();
    }

    /* istanbul ignore next */
    formatLinkData(data) {
        return {
            omniture: ((window.digitalData.page.pageInfo.section) ? window.digitalData.page.pageInfo.section : "smart-troubleshoot")
                + ":" + ((data.authenticated) ? 'authenticated' : "unauthenticated") + ":" + data.path.join(":"),
            ga: {}
        }
    }

    /* istanbul ignore next */
    formatPageData(data) {
        let pageName
        if(data.path){pageName = data.path.join(":");
        }else{
            pageName = data?.data?.pageName;
        }
        return {
            omniture: pageName,
            ga: {}
        }
    }

    getEventType(data) {
        return {
            omniture: data.eventType,
            ga: "click"
        };
    }
}


const appAnalytics = new AppAnalytics();

window.appAnalytics = appAnalytics;

export default appAnalytics;
