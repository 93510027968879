import React from "react";
import Issue from "../Issue";
import icons from "../../common/IconsList";

const ISSUES = {
    "MOBILE": [
        new Issue({
            name: "Mobile internet",
            label: "Mobile internet",
            iconClass: icons.apps247Icon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Mobile internet"
        }),
        new Issue({
            name: "Mobile voice calls",
            label: "Calls",
            iconClass: icons.productPhoneIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Mobile voice calls"
        }),
        new Issue({
            name: "Messaging",
            label: "Messaging",
            iconClass: icons.textIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Messaging"
        }),
        new Issue({ // @TODO enable this as revert for [#168159672]
            name: "INTERNATIONAL_ROAMING_ISSUES",
            label: "International Roaming / Calling",
            iconClass: icons.productInternationalRoamingIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "International Roaming"
        }),
        new Issue({
            name: "Email",
            label: "Email",
            iconClass: icons.emailIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Email"
        }),// @TODO move message bank to other and coverage to top #168745129
        new Issue({
            name: "MOBILE_COVERAGE",
            label: "Mobile coverage",
            iconClass: icons.coverageIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Mobile coverage"
        }),
        new Issue({
            name: "Other",
            label: "Other",
            iconClass: icons.helpSupportIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Other Voice Calling Issues"
        })
    ],
    "Mobile internet":[
        new Issue({
            name: "CANNOT_CONNECT_TO_THE_INTERNET",
            label: "There is no connection",
            iconClass: icons.controlsAlertNotificationIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Cannot connect to the internet"
        }),
        new Issue({
            name: "INTERNET_DROPOUT",
            label: "It is dropping out",
            iconClass: icons.greaterReliabilityIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Internet Dropout"
        }),
        new Issue({
            name: "MOBILE_SPEED_IS_SLOW",
            label: "Speed is slow",
            iconClass: icons.rechargeIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Mobile speed is slow"
        }),
        new Issue({
            name: "OTHER_VOICE_CALLING_ISSUES",
            label: "Issue with specific website",
            iconClass: icons.newsIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Other Voice Calling Issues"
        })
    ],
    "Messaging":[
        //TODO disabling cannot send sms flow has it is combined in MMS flow
        /*    new Issue({
                name: "CANNOT_SEND_SMS",
                label: "Cannot send text messages",
                iconClass: icons.apps247Icon,
                workflowName: "NBN Controller Workflow 2.0",
                diagnosticTestName: "precheck",
                snowSymptomName: "Cannot send SMS"
            }),*/
        new Issue({
            name: "CANNOT_RECEIVE_MESSAGES",
            label: "Cannot receive",
            iconClass: icons.cannotReceiveSmsIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Cannot receive messages"
        }),
        new Issue({
            name: "CANNOT_SEND_MMS",
            label: "Cannot send",
            iconClass: icons.cannotSendSmsIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Cannot send MMS"
        }),
        new Issue({
            name: "MOBILE_SUPPORT",
            label: "Repetitive text messages",
            iconClass: icons.transferPlanIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Repetitive text messages"
        }),
        new Issue({
            name: "MOBILE_SUPPORT",
            label: "Premium SMS issues",
            iconClass: icons.starIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Premium SMS issues"
        }),
        new Issue({
            name: "MOBILE_SUPPORT",
            label: "Delayed text messages",
            iconClass: icons.timeIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Delayed text messages"
        }),
        new Issue({
            name: "MOBILE_SUPPORT",
            label: "Messages not in chronological order",
            iconClass: icons.menuIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Messages not in chronological order"
        })
    ],
    "Mobile voice calls":[
        new Issue({
            name: "UNABLE_TO_MAKE_CALLS",
            label: "Cannot make calls",
            iconClass: icons.cannotMakeCallIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Unable to make calls"
        }),
        new Issue({
            name: "UNABLE_TO_RECEIVE_CALLS",
            label: "Cannot receive calls",
            iconClass: icons.cannotReceiveCallIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Unable to receive calls"
        }),
        new Issue({
            name: "POOR_CALL_QUALITY",
            label: "Call quality is poor",
            iconClass: icons.socialThumbsDownIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Poor Call Quality"
        }),
        new Issue({
            name: "CALL_DROP_OUTS",
            label: "Call is dropping out",
            iconClass: icons.greaterReliabilityIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Call Drop Outs"
        }),
        new Issue({
            name: "CALLER_ID",
            label: "Caller ID",
            iconClass: icons.callerIdIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Voice Call caller Id"
        })/*,
            new Issue({
                name: "MOBILE_SUPPORT",
                label: ["MessageBank",<sup>®</sup>,"issues"],
                iconClass: icons.apps247Icon,
                workflowName: "NBN Controller Workflow 2.0",
                diagnosticTestName: "precheck",
                snowSymptomName: "Cannot access Message bank"
            })*/
    ],
    "Email":[
        new Issue({
            name: "MOBILE_SUPPORT",
            label: "Cannot send emails",
            iconClass: icons.cannotSendEmailIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Cannot send emails"
        }),
        new Issue({
            name: "MOBILE_SUPPORT",
            label: "Cannot receive emails",
            iconClass: icons.cannotReceiveEmailIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Cannot receive emails"
        }),
        new Issue({
            name: "MOBILE_SUPPORT",
            label: "Email configuration issues",
            iconClass: icons.settingsIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Email configuration issues"
        })
    ],
    "Other":[ // @TODO move message bank to other and coverage to top #168745129
        new Issue({
            name: "CANNOT_ACCESS_MESSAGE_BANK",
            label: ["MessageBank",<sup>®</sup>,"issues"],
            iconClass: icons.messageBankIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Cannot access Message bank"
        }),
        new Issue({
            name: "MOBILE_OUTAGE",
            label: "Mobile outages",
            iconClass: icons.alarmIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Mobile outages"
        }),
        new Issue({
            name: "CALL_US",
            label: "SIM replacement",
            iconClass: icons.simCardIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "SIM replacement"
        }),
        new Issue({
            name: "FAULTY_DEVICE",
            label: "Faulty device",
            iconClass: icons.mobileIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Faulty device"
        }),
        new Issue({
            name: "CALL_US",
            label: "Lost or stolen device",
            iconClass: icons.DeviceProtectIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Lost or stolen device"
        }),
        new Issue({ // @TODO enable to all environemnt if ready to go to production for International Calling [#168823763]
            name: "TELSTRA_ONE_NUMBER",
            label: "Telstra One Number",
            iconClass: icons.number1Icon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "Telstra One Number"
        })
    ],
    "INTERNATIONAL_ROAMING_ISSUES":[
        new Issue({
            name: "INTERNATIONAL_ROAMING",
            label: "Cannot use service overseas",
            iconClass: icons.productInternationalRoamingIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "International Roaming"
        }),
        new Issue({
            name: "INTERNATIONAL_CALLING",
            label: "Cannot make international calls",
            iconClass: icons.internationalDestinationIcon,
            workflowName: "NBN Controller Workflow 2.0",
            diagnosticTestName: "precheck",
            snowSymptomName: "International Calling"
        })
    ]
};

export default ISSUES;
