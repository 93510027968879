
let setCookie = (cdomain, cname, cvalue)=>{
    var d = new Date();
    d.setTime(d.getTime() + (60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain="+cdomain+";secure";
};

const getCookie = (cname)=>{
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

const deleteCookie = (cdomain, cname)=>{
	var d = new Date();
	d.setTime(d.getTime() + (60*60*1000));
	var expires = "expires=Thu, 01 Jan 1970 00:00:01 GMT";
	document.cookie = cname + "=;" + expires + ";path=/;domain="+cdomain+";secure";
};

export {
    setCookie,
    getCookie,
	deleteCookie
}