import React from "react";
import MobileSymptomList from "./MobileSymptomList";

function  getSymptomOnName(name){
    let itemValue=[];
    const mobile=["MOBILE","Mobile internet","Messaging","Mobile voice calls","Email","Other","INTERNATIONAL_ROAMING_ISSUES"]
    for (let i = 0; i < mobile.length; i++) {
        if(itemValue.length===1) break;
        itemValue=MobileSymptomList[mobile[i]].filter((item)=>{
           return item.name === name;
        });
    }
 return itemValue;
};




const allSymptom=[
    {
        name: "EMAIL_ISSUES",
        label: "Email Issues",
        snowSymptomName: "Email Issues"
    },
    {name: "Mobile internet",
        label: "Mobile internet",
        snowSymptomName: "Mobile internet"
    },
    {
        name: "Mobile voice calls",
        label: "Calls",
        snowSymptomName: "Mobile voice calls"
    },
    {
        name: "Messaging",
        label: "Messaging",
        snowSymptomName: "Messaging"
    },
    {
        name: "INTERNATIONAL_ROAMING_ISSUES",
        label: "International Roaming / Calling",
        snowSymptomName: "International Roaming"
    },
    {
        name: "Email",
        label: "Email",
        snowSymptomName: "Email"
    },
    {
        name: "MOBILE_COVERAGE",
        label: "Mobile coverage",
        snowSymptomName: "Mobile coverage"
    },
    {
        name: "Other",
        label: "Other",
        snowSymptomName: "Other Voice Calling Issues"
    },
    {
        name: "CANNOT_CONNECT_TO_THE_INTERNET",
        label: "There is no connection",
        snowSymptomName: "Cannot connect to the internet"
    },
    {
        name: "CANNOT_CONNECT_TO_THE_INTERNET",
        label: "There is no connection",
        snowSymptomName: "Unable to connect to the internet"
    },
    {
        name: "INTERNET_DROPOUT",
        label: "It is dropping out",
        snowSymptomName: "Internet Dropout"
    },
    {
        name: "MOBILE_SPEED_IS_SLOW",
        label: "Speed is slow",
        snowSymptomName: "Mobile speed is slow"
    },
    {
        name: "OTHER_VOICE_CALLING_ISSUES",
        label: "Issue with specific website",
        snowSymptomName: "Other Voice Calling Issues"
    },
    {
        name: "CANNOT_RECEIVE_MESSAGES",
        label: "Cannot receive",
        snowSymptomName: "Cannot receive messages"
    },
    {
        name: "CANNOT_SEND_MMS",
        label: "Cannot send",
        snowSymptomName: "Cannot send MMS"
    },
    {
        name: "MOBILE_SUPPORT",
        label: "Repetitive text messages",
        snowSymptomName: "Repetitive text messages"
    },
    {
        name: "MOBILE_SUPPORT",
        label: "Premium SMS issues",
        snowSymptomName: "Premium SMS issues"
    },
    {
        name: "MOBILE_SUPPORT",
        label: "Delayed text messages",
        snowSymptomName: "Delayed text messages"
    },
    {
        name: "MOBILE_SUPPORT",
        label: "Messages not in chronological order",
        snowSymptomName: "Messages not in chronological order"
    },
    {
        name: "UNABLE_TO_MAKE_CALLS",
        label: "Cannot make calls",
        snowSymptomName: "Unable to make calls"
    },
    {
        name: "UNABLE_TO_RECEIVE_CALLS",
        label: "Cannot receive calls",
        snowSymptomName: "Unable to receive calls"
    },
    {
        name: "POOR_CALL_QUALITY",
        label: "Call quality is poor",
        snowSymptomName: "Poor Call Quality"
    },
    {
        name: "CALL_DROP_OUTS",
        label: "Call is dropping out",
        snowSymptomName: "Call Drop Outs"
    },
    {
        name: "MOBILE_SUPPORT",
        label: "Cannot send emails",
        snowSymptomName: "Cannot send emails"
    },
    {
        name: "MOBILE_SUPPORT",
        label: "Cannot receive emails",
        snowSymptomName: "Cannot receive emails"
    },
    {
        name: "MOBILE_SUPPORT",
        label: "Email configuration issues",
        snowSymptomName: "Email configuration issues"
    },
    {
        name: "MESSAGE_BANK",
        label: ["MessageBank",<sup>®</sup>,"issues"],
        snowSymptomName: "Cannot access Message bank"
    },
    {
        name: "MOBILE_OUTAGE",
        label: "Mobile outages",
        snowSymptomName: "Mobile outages"
    },
    {
        name: "CALL_US",
        label: "SIM replacement",
        snowSymptomName: "SIM replacement"
    },
    {
        name: "FAULTY_DEVICE",
        label: "Faulty device",
        snowSymptomName: "Faulty device"
    },
    {
        name: "CALL_US",
        label: "Lost or stolen device",
        snowSymptomName: "Lost or stolen device"
    },
    {
        name: "TELSTRA_ONE_NUMBER",
        label: "Telstra One Number",
        snowSymptomName: "Telstra One Number"
    },
    {
        name: "INTERNATIONAL_ROAMING",
        label: "Cannot use service overseas",
        snowSymptomName: "International Roaming"
    },
    {
        name: "INTERNATIONAL_CALLING",
        label: "Cannot make international calls",
        snowSymptomName: "International Calling"
    },
    {
        name: "INTERNET_DOWN",
        label: "There is no connection",
        snowSymptomName: "Cannot Connect"
    },
    {
        name: "DROPPING_OUT",
        label: "It's dropping out",
        snowSymptomName: "Loss of Connection/Intermittent Problems"
    },
    {
        name: "INTERNET_SLOW",
        label: "Speed is slow",
        snowSymptomName: "Speed is slow"
    },
    {
        name: "WIFI_ISSUE",
        label: "Wi-Fi connection and equipment issues",
        snowSymptomName: "Wi-Fi troubleshooting"
    },
    {
        name: "WIFI_ISSUE",
        label: "Wi-Fi connection and equipment issues",
    },
    {
        name: "INTERNET_DOWN",
        label: "There is no connection",
        snowSymptomName: "Cannot Connect"
    },
    {
        name: "DROPPING_OUT",
        label: "It's dropping out",
    },
    {
        name: "INTERNET_SLOW",
        label: "Speed is slow",
    },
    {
        name: "WIFI_ISSUE",
        label: "Wi-Fi connection and equipment issues",
    },
    {
        name: "NO_CALLS",
        label: "Cannot make/receive calls",
        snowSymptomName: "Unable to make and receive calls"
    },
    {
        name: "MESSAGE_BANK",
        label: ["Issues with MessageBank",<sup>®</sup>],
        snowSymptomName: "MessageBank"
    },
    {
        name: "OTHER_PHONE_PROBLEM",
        label: "Other",
    },
    {
        name: "WIFI_ISSUES",
        label: "Wi-Fi is dropping out",
        snowSymptomName: "Wi-Fi is dropping out" //TODO
    },
    {
        name: "WIFI_ISSUES",
        label: "Wi-Fi speed is slow",
        snowSymptomName: "Wireless Connectivity" //TODO
    },
    {
        name: "WIFI_ISSUES",
        label: "Wi-Fi speed is slow",
        snowSymptomName: "Wi-Fi speed is slow" //TODO
    },
    {
        name: "Device configuration",
        label: "Device configuration",
    },
    {
        name:"INTERNET_SLOW",
        label:"Internet is Slow",
        snowSymptomName:"Internet is Slow"
    },{
        name: "WIFI_ISSUES",
        label: "Wi-Fi speed is slow",
        snowSymptomName: "Wi-Fi Connection Issues" //TODO
    }

];

const  AllSymptomList=(snowName)=>{
    let symptomData;
    for(var s in allSymptom){
        if(allSymptom[s].snowSymptomName === snowName){
            symptomData=allSymptom[s];
            break;
        }
    }
   return  symptomData;
};


//export default getSymptomOnName ;

export function FindAllSymptomList (name){ return getSymptomOnName(name)};

export default AllSymptomList;
