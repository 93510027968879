import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="#414141" fillRule="evenodd" transform="translate(1 1)">
		<path d="M11 4.43A3.6 3.6 0 0 0 7.4 8h1.2a2.4 2.4 0 1 1 2.4 2.4h-.6v3.81h1.2v-2.63a3.6 3.6 0 0 0-.6-7.15Z"/>
		<path d="M11 .4C5.146.4.4 5.146.4 11c0 5.854 4.746 10.6 10.6 10.6 5.854 0 10.6-4.746 10.6-10.6C21.589 5.15 16.85.411 11 .4Zm0 20a9.4 9.4 0 1 1 9.4-9.4 9.41 9.41 0 0 1-9.4 9.4Z" fillRule="nonzero"/>
		<circle cx="11" cy="16.5" r="1"/>
	</g>
</svg>); 
 }; 
 export default SVG;