import React, {Component} from 'react';
import { Route, withRouter } from 'react-router-dom';
import AppLog from "./util/AppLog";
import DataContext from "./context/DataContext";
import {redirectToSpecifiedLocation} from "../service/util/serviceUtil";

const log = new AppLog("RouteWrapper");

/* istanbul ignore next*/
class RouteWrapper extends Component {
    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.component !== prevState.loadComponent) {
            return {
                loadComponent: nextProps.component,
                component: nextProps.component
            }
        }
    }
    constructor(props) {
        super(props);
        window.selfserviceHistory = this.props.history;
        this.state = {
            component: this.props.component,
            loadComponent: this.props.component
        }
        if(this.props.onEnter){
            this.state.component = (this.props.loadingComponent)?this.props.loadingComponent:()=>{
                return (<section>
                    <h1 className={"margin-clean margin-bottom-l"}>Loading Routes</h1>
                </section>)
            };
            this.props.onEnter().then(data=>{
                log.info("Route Loaded:"+this.props.path, this.state);
                this.setState({
                    component: this.state.loadComponent,
                    data: data
                });
            }).catch(err=>{
                //this.props.history.push("/error");
                redirectToSpecifiedLocation(this.props.history, "/error");
                log.error("Route On Enter Error:"+this.props.path, err);
            })
            log.info("Route On Entering:"+this.props.path, this.state);
        }else{
            log.info("Route Loaded:"+this.props.path, this.state);
        }
    }
    render() {
        const {component,data} = this.state;
        const LinkedContext = this.props.context || DataContext;
        return (
            <LinkedContext.Provider value={data}>
                <Route {...this.props} component={component}/>
            </LinkedContext.Provider>
        );
    }
}

export default withRouter(RouteWrapper);
