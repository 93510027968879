import React from "react";
import { AuthConsumer } from "../identity/caiman/AuthProvider";
import {DEEPLINK_TECHTYPE} from "../service/util/serviceUtil";

const setDeeplinkStorage = () => {
    const search = window.location.search.substring(1);
    sessionStorage.setItem(
        "deeplink",
        ('{"' + decodeURI(search.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}'),
    );
}

export const DeepLink = () => (
    //store queryparam from deeplink here
    // no need of routing to deep link ("/centralised-route") handled by Kartheek/Shalini
    // route to select service will handle the userContext and plan is to utilise deeplink utility (which checks deeplink params
    // present) at component level (selectService & FaultIncident)
    // As discussed, FaultTracker deep link can be handled with NBN SHC deeplink on basis of request and response
    <AuthConsumer>
        {({ isAuthenticated, signinRedirect }) => {
            // this overrides existing value of the deeplink storage, irrespective of the user's session there or not
            setDeeplinkStorage();
            if (isAuthenticated()) {
                // sessionStorage.setItem("deeplink",JSON.stringify(queryParam));
                const deepLink = (sessionStorage && sessionStorage.hasOwnProperty("deeplink")) ? JSON.parse(sessionStorage.getItem("deeplink")) : {};
                if (deepLink.type && deepLink.type == "FAULTTRACKER" || sessionStorage.getItem("target_redirect_route") === "/smart-troubleshooting/deeplink/faulttracker") {
                    window.location = "/smart-troubleshooting/deeplink/faulttracker"
                    return;
                } else if (deepLink.action && (deepLink.incident || deepLink.incidentId)){
                    window.location = "/smart-troubleshooting/deeplink/resumeIncident"
                    return;
                }
                else if (sessionStorage.getItem("isError") && sessionStorage.getItem("isError") === "true"){
                    window.location = "/smart-troubleshooting/error"
                    return;
                }
                else{
                    window.location = "/smart-troubleshooting/select-service"
                    return;
                }
            } else {
                // remove existing values just in case, to prevent dormant values just in case.
                sessionStorage.removeItem("isError");
                signinRedirect();
            }
        }}
    </AuthConsumer>
);
