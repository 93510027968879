import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<defs>
		<path id="id-0a" d="M0 0h18v23.599H0z"/>
		<path id="id-2c" d="M0 0h18v23.599H0z"/>
	</defs>
	<g fill="none" fillRule="evenodd" transform="translate(3)">
		<mask id="id-1b" fill="#fff">
			<path d="M0 0h18v23.599H0z"/>
		</mask>
		<mask id="id-3d" fill="#fff">
			<path d="M0 0h18v23.599H0z"/>
		</mask>
		<path d="M0 23.599h18V22.4H0z" fill="#0A0B09" fillRule="nonzero" mask="url(#id-1b)"/>
		<path d="M9.606.001 8.407-.001l-.007 3.5 1.2.002.006-3.5" fill="#0A0B09" fillRule="nonzero" mask="url(#id-3d)"/>
		<path d="M3.6 12c0-2.978 2.422-5.4 5.4-5.4 2.977 0 5.4 2.422 5.4 5.4v8.4H3.6V12Zm-1.2 9.6h13.2V12c0-3.64-2.96-6.6-6.6-6.6-3.64 0-6.6 2.96-6.6 6.6v9.6Z" fill="#0A0B09" fillRule="nonzero"/>
		<path d="M9 7.9v1.2c1.599 0 2.9 1.301 2.9 2.9h1.2c0-2.261-1.839-4.1-4.1-4.1M3.425 5.572.944 3.086l-.849.848 2.48 2.486.85-.848M17.914 3.944l-.848-.849-2.478 2.472.847.85 2.479-2.473" fill="#0A0B09" fillRule="nonzero"/>
	</g>
</svg>); 
 }; 
 export default SVG;