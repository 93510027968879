import React from "react";
import BaseRunTimeContainer from './BaseRunTimeContainer';
import {BrowserRouter, Switch, Redirect, Route} from "react-router-dom";
import RouteWrapper from "./RouteWrapper";
import IdentityClient from "../identity/client/IdentityClient";
import OmnitureTracker from "../common/util/OmnitureTracker";
import Loading from "./Loading";
import UserContext from "../identity/context/UserContext";
import CIPLogin from "../identity/CIPLogin";
import TransferToAgentMessage from "./TransferToAgentMessage";
import Loadable from "react-loadable";
import {AuthProvider} from "../identity/caiman/AuthProvider";
import {Callback} from "../identity/caiman/Callback";
import {DeepLink} from "../deeplink/DeepLink";
import FaultTrackerAppointment from "../faulttracker/FaultTrackerAppointment";
import RedirectFaultTracker from "../faulttracker/RedirectFaultTracker";
import Loader from "./Loader";

const appPath = "/smart-troubleshooting";

const Authorised = Loadable({
    loader: () => import('../identity/Authorised'),
    loading() {
        return Loading()
    }
});
const UnAuthorised = Loadable({
    loader: () => import('../identity/UnAuthorised'),
    loading() {
        return Loading()
    }
});
const SmartModemHelp = Loadable({
    loader: () => import('../smartmodem/SmartModemHelp'),
    loading() {
        return Loading()
    }
});

export default class App extends BaseRunTimeContainer{

    constructor(props) {
        super(props, ["GlobalFooter", "Wrapper"]);
        this.state={
            updateButtonClass: false
        }
    }
    signOutHandler(){
    }
    render(){
        const {GlobalFooter, Wrapper} = this.runTimeComponents;
        //@TODO implement omniture tracking
        return (
            <AuthProvider>
                <BrowserRouter>
                    <OmnitureTracker/>
                    <Wrapper>
                        <div className="rt-section">
                        <Switch>
                            <RouteWrapper path={appPath+"/u"} component={UnAuthorised} loadingComponent={Loading}/>
                            <RouteWrapper path={appPath+"/h"} component={UnAuthorised} loadingComponent={Loading}/>
                            <RouteWrapper path={appPath+"/i"} component={UnAuthorised} loadingComponent={Loading}/>
                            <RouteWrapper exact path="/assurance/postauth" component={Callback} loadingComponent={Loading}/>
                            <RouteWrapper path ={appPath+"/fault-track/appointment"} component={FaultTrackerAppointment} loadingComponent={Loading} />
                            <RouteWrapper path={appPath} component={Authorised} onEnter={IdentityClient.fetchUserData} loadingComponent={Loading} context={UserContext}/>
                            <RouteWrapper exact path="/smartmodem" component={SmartModemHelp} loadingComponent={Loading}/>
                            <RouteWrapper  exact path="/deeplink" component={DeepLink} loadingComponent={Loading}/>
                            <RouteWrapper  exact path="/redirect-fault-tracker" component={RedirectFaultTracker} loadingComponent={Loading}/>
                            {/*Below route is added for handling unauth redirection happening via other apps and doing caiman authentication for Fix.t through OIDC*/}
                            <RouteWrapper  exact path="/login-initiate" component={Loader}/>
                            <Route  exact path="/faulttracker/:ticketNumber" render={({ match }) => (
                                <Redirect to={`/smart-troubleshooting/u/fault/${match.params.ticketNumber}${window.location.search}`} />
                            )} />
                            <RouteWrapper exact path="/" component={CIPLogin} />
                            <RouteWrapper exact path="/login" component={CIPLogin} />
                            <Route exact path="/faulttracker/:ticketNumber" render={({ match }) => (
                                <Redirect to={`/smart-troubleshooting/u/fault/${match.params.ticketNumber}${window.location.search}`} />
                                )} />
                            <RouteWrapper exact path="*" component={TransferToAgentMessage} />

                            {/*@TODO: migrate store-visit*/}
                                {/*@TODO: migrate fault-tracker*/}
                                {/*@TODO: migrate gathering-details (IVR)*/}
                                {/*@TODO: migrate url-shorter*/}
                                {/*@TODO: migrate send-otp*/}
                                {/*@TODO: migrate verify-otp*/}
                                {/*{ Deprecated now after OIDC implementation
                                    (window.metadata.app.environment === 'ci' || window.metadata.app.environment === 'development') &&
                                    <RouteWrapper exact path="/mockcip" component={MockCIPLoginForm} />
                                }*/}
                        </Switch>
                        </div>
                    </Wrapper>
                    <GlobalFooter />
                </BrowserRouter>
            </AuthProvider>
        )
    }
}
