import cache, {APP_CACHE} from "./cacheManager";
import appAnalytics from "./AppAnalytics";

const newrelic = window.newrelic || {
    setCustomAttribute: () => {
    }
};

function validateParams(params) {
    if(params === null || params === undefined ){
        return 'Not seeing the service i need help with in selfcare';
    }
    return params;
}

export function messageUs() {
    appAnalytics.send({
        eventType: "eventInfo",
        data:{
            "eventAction": "click",
            "eventType": "clickTrack",
            "eventCategory": "buttonClick",
            "eventName":"Message us"
        },
    });
    let selectedService = cache.getProperty(APP_CACHE.SELECTED_SERVICE, undefined) || {technologyType: ''};
    let selectedIssue = cache.getProperty(APP_CACHE.SELECTED_ISSUE, undefined) || '';
    let incidentNumber = cache.getProperty(APP_CACHE.INCIDENT_NUMBER, undefined) || '';
    let firstName = window.userDetail && window.userDetail.firstName ? window.userDetail.firstName : '';
    let flag = cache.getProperty(APP_CACHE.MOBILE_TYPE) === "ANDROID" ? true : false;

    if (typeof selectedService === "string") {
        selectedService = JSON.parse(selectedService)
    }
    if(Object.keys(selectedService).length == 0) {
        selectedService.technologyType='';
    }
    incidentNumber = typeof incidentNumber === "string" ? incidentNumber : "";

    console.log('message us for ', selectedService, ' type ', typeof (selectedService), 'product name', selectedService.productName, 'technologyType' , selectedService.technologyType, ' firstName ', firstName, ' incidentNumber ', incidentNumber, selectedIssue.label)

    let message = "Customer: " + validateParams(firstName) + (incidentNumber ? " <br> Fault incident ID: " + "(" +incidentNumber + ")" :'Not seeing the incident i need help with in selfcare')+ " <br> Service ID: " + validateParams(selectedService.serviceId) + " <br> Symptom ID: " + validateParams(selectedIssue.label) + " "

    window.lpTag.newPage(document.URL, {section: ["telstra", "consumer", "troubleshoot", "help"]});
    switch (true) {

        case flag && ("Internet Service" === selectedService.productName  ||  "Voice Service" === selectedService.productName)  && !["adsl", "cable","5gfms" ].includes((selectedService.technologyType.toString()).toLowerCase()):

            window.location.href = "https://open.mytelstra.app/BJRB/df795058";
            break;

        case "Internet Service" === selectedService.productName && ["adsl", "cable"].includes((selectedService.technologyType.toString()).toLowerCase()):
            window.livePerson.show({
                isDirect: true,
                section: ["telstra", "consumer", "troubleshoot", "help", "broadband", "dtq"]
            });
            window.lpTag.sdes.push(
                {
                    "type": "error", //MANDATORY
                    "error": {
                        "contextId": selectedService.technologyType + " Troubleshooting", //Heading
                        "message": message
                    }
                });
            break;
        case ["mobile"].includes((selectedService.technologyType.toString()).toLowerCase()):
            window.livePerson.show({
                isDirect: true,
                section: ["telstra", "consumer", "troubleshoot", "help", "mobile", "dtq"]
            });
            window.lpTag.sdes.push(
                {
                    "type": "error", //MANDATORY
                    "error": {
                        "contextId": selectedService.technologyType + " Troubleshooting", //Heading
                        "message": message
                    }
                });
            break;

        case "Internet Service" === selectedService.productName && ["5gfms"].includes((selectedService.technologyType.toString()).toLowerCase()):
            window.livePerson.show({
                isDirect: true,
                section: ["telstra", "dtq", "service", "airwave", "non-nbn"]
            });
            window.lpTag.sdes.push(
                {
                    "type": "error", //MANDATORY
                    "error": {
                        "contextId": selectedService.technologyType + " Troubleshooting", //Heading
                        "message": message
                    }
                });
            break;

        case "Internet Service" === selectedService.productName:
            window.livePerson.show({
                isDirect: true,
                section: ["telstra", "consumer", "troubleshoot", "help", "nbn", "dtq"]
            });
            window.lpTag.sdes.push(
                {
                    "type": "error", //MANDATORY
                    "error": {
                        "contextId": selectedService.technologyType + " Troubleshooting", //Heading
                        "message": message
                    }
                });
            break;

        case "Voice Service" === selectedService.productName:
            window.livePerson.show({
                isDirect: true,
                section: ["telstra", "consumer", "troubleshoot", "help", "voice", "dtq"]
            });
            window.lpTag.sdes.push(
                {
                    "type": "error", //MANDATORY
                    "error": {
                        "contextId": selectedService.technologyType + " Troubleshooting", //Heading
                        "message": message
                    }
                });
            break;

        case "Applications & Entertainment Services" === selectedService.productName && ["email"].includes((selectedService.technologyType.toString()).toLowerCase()):
            window.livePerson.show({
                isDirect: true,
                section: ["telstra", "consumer", "troubleshoot", "help", "email", "dtq"]
            });
            window.lpTag.sdes.push(
                {
                    "type": "error", //MANDATORY
                    "error": {
                        "contextId": selectedService.technologyType + " Troubleshooting", //Heading
                        "message": message
                    }
                });
            break;


        default:
            console.log('window.livePerson now talking to CODI')
            window.livePerson.show({
                isDirect: true,
                section: ["consumer", "dtq", "help", "telstra", "troubleshoot"]
            });
            break;
    }
    newrelic.setCustomAttribute("web-parent-message-us", JSON.stringify({
        selectedService,
        selectedIssue,
        incidentNumber,
        message
    }));
}
