import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<defs>
		<path id="id-4a" d="M12 1.4c5.85.011 10.589 4.75 10.6 10.6 0 5.854-4.746 10.6-10.6 10.6-5.854 0-10.6-4.746-10.6-10.6C1.4 6.146 6.146 1.4 12 1.4Zm0 1.2a9.4 9.4 0 1 0 0 18.8 9.41 9.41 0 0 0 9.4-9.4A9.4 9.4 0 0 0 12 2.6Zm.93 12.52V17h-1.86v-1.88h1.86ZM12.91 7v3.13l-.39 3.88h-1.06l-.39-3.88V7h1.84Z"/>
	</defs>
	<g fill="none" fillRule="evenodd">
		<mask id="id-5b" fill="#fff">
			<path d="M12 1.4c5.85.011 10.589 4.75 10.6 10.6 0 5.854-4.746 10.6-10.6 10.6-5.854 0-10.6-4.746-10.6-10.6C1.4 6.146 6.146 1.4 12 1.4Zm0 1.2a9.4 9.4 0 1 0 0 18.8 9.41 9.41 0 0 0 9.4-9.4A9.4 9.4 0 0 0 12 2.6Zm.93 12.52V17h-1.86v-1.88h1.86ZM12.91 7v3.13l-.39 3.88h-1.06l-.39-3.88V7h1.84Z"/>
		</mask>
		<path d="M12 1.4c5.85.011 10.589 4.75 10.6 10.6 0 5.854-4.746 10.6-10.6 10.6-5.854 0-10.6-4.746-10.6-10.6C1.4 6.146 6.146 1.4 12 1.4Zm0 1.2a9.4 9.4 0 1 0 0 18.8 9.41 9.41 0 0 0 9.4-9.4A9.4 9.4 0 0 0 12 2.6Zm.93 12.52V17h-1.86v-1.88h1.86ZM12.91 7v3.13l-.39 3.88h-1.06l-.39-3.88V7h1.84Z" fill="#414141" fillRule="nonzero"/>
		<g fill="#414141" mask="url(#id-5b)">
			<path d="M0 0h24v24H0z"/>
		</g>
	</g>
</svg>); 
 }; 
 export default SVG;