import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="#414141" fillRule="evenodd">
		<circle cx="7.5" cy="20.5" r="1"/>
		<path d="M5.5 2.4h4v1.2h-4z"/>
		<path d="M16.5 10.1a5.43 5.43 0 0 0-1.9.34V.4H.4v23.2h14.2V21a5.43 5.43 0 0 0 1.9.34 5.6 5.6 0 1 0 0-11.2v-.04Zm-3.1 12.3H1.6V1.6h11.8V11a5.59 5.59 0 0 0 0 9.32v2.08Zm3.1-2.3a4.4 4.4 0 1 1 0-8.8 4.4 4.4 0 0 1 0 8.8Z" fillRule="nonzero"/>
		<path d="M17.19 15.12h-.26v-1.43a.83.83 0 0 1 .68.75h.93a1.57 1.57 0 0 0-.54-1.09 1.94 1.94 0 0 0-1.09-.47v-.72h-.56v.7a2 2 0 0 0-1.23.55 1.4 1.4 0 0 0 .88 2.44l.37.05v1.51a1 1 0 0 1-.61-.27.89.89 0 0 1-.28-.64h-.94c.013.447.208.87.54 1.17a2 2 0 0 0 1.29.54v.69h.56v-.69a2 2 0 0 0 1.24-.56c.285-.282.44-.67.43-1.07a1.49 1.49 0 0 0-1.41-1.46Zm-.82-.12h-.22a.55.55 0 0 1-.54-.57.68.68 0 0 1 .2-.48 1.06 1.06 0 0 1 .56-.25V15Zm1.1 2.15a1 1 0 0 1-.54.25V16h.13c.33.06.62.23.62.61a.7.7 0 0 1-.21.54Z" fillRule="nonzero"/>
	</g>
</svg>); 
 }; 
 export default SVG;