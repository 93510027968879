import React from "react";
import {UserManager, WebStorageStateStore, Log} from "oidc-client";
import appAnalytics from "../../common/util/AppAnalytics";
import {redirectToMTW} from "../../service/util/serviceUtil";

const getAppBFF = () => {
    return (window.metadata && window.metadata.appBff) ? window.metadata.appBff :/* istanbul ignore next*/"";
}

const newrelic = window.newrelic || {
    setCustomAttribute: () => {
    }
};

const redirectToDeeplinkFlow = (deepLink) => {
    if (deepLink.type && deepLink.type == "FAULTTRACKER" || sessionStorage.getItem("target_redirect_route") === "/smart-troubleshooting/deeplink/faulttracker") {
        window.location = "/smart-troubleshooting/deeplink/faulttracker";
        sessionStorage.removeItem("target_redirect_route");
        return;
    } else if (deepLink.action && deepLink.incident){
        window.location = "/smart-troubleshooting/deeplink/resumeIncident"
        return;
    }
    else if (deepLink?.techtype){
        redirectToMTW(deepLink?.techtype)
    }
    else if (deepLink?.serviceType && deepLink?.symptom){
        redirectToMTW(deepLink?.serviceType)
    }
    else if (deepLink?.serviceType){
        redirectToMTW(deepLink?.serviceType)
    }
    else if (deepLink?.token){
        window.location = "/smart-troubleshooting/select-service"
    }
    else{
        window.location = window.metadata.app.myTGetHelpRoute;
        return;
    }
}

const CAIMAN_IDENTITY_CONFIG = window.metadata.app.caiman.IDENTITY_CONFIG;
const CAIMAN_METADATA_OIDC = window.metadata.app.caiman.METADATA_OIDC;
const SHARED_FLOW_URL = window.metadata.app.sharedFlowUrl;
const POST_TIMEOUT_LOGOUT_REDIRECT = window.metadata.app.postTimeoutLogoutRedirect;
const POST_LOGOUT_REDIRECT = window.metadata.app.postLogoutRedirect;

export default class AuthService {
    UserManager;

    constructor() {
        appAnalytics.send({
            eventType: "pageName",
            path: ["caiman-oidc-auth-service"]
        });
        this.UserManager = new UserManager({
            ...CAIMAN_IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({store: window.sessionStorage}),
            metadata: {
                ...CAIMAN_METADATA_OIDC
            }
        });
        // Logger
        Log.logger = console;
        Log.level = Log.DEBUG;
        this.UserManager.events.addUserLoaded(async (user) => {
            console.log("check add user called");
            if (window.location.href.indexOf("/assurance/postauth") !== -1) {
                //this.navigateToScreen();    // Need to check if really required for route
            }
            console.log("sign in silent callback user ---->", user);
            if (sessionStorage.hasOwnProperty("SILENT_SIGN_IN") && sessionStorage.getItem("SILENT_SIGN_IN") === "true") {
                sessionStorage.removeItem("SILENT_SIGN_IN")
                await this.getCipLoginFlow(user.access_token, user.id_token, true);
            }
        });
        this.UserManager.events.addSilentRenewError((e) => {
            newrelic.setCustomAttribute("silent-renew-failed", "Error while silent sign in of the user" + e.message);
            console.log("silent renew error", e.message);
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            console.log("user's token(s) expired - clearing storage, etc.");
            this.UserManager.clearStaleState();
            this.UserManager.removeUser();
            // this.logout();       // Explicitly required to handle for signout scenario
        });
        this.UserManager.events.addAccessTokenExpiring(() => {
            console.log("Trying to silent login");
            sessionStorage.setItem("SILENT_SIGN_IN", "true");
            this.UserManager.signinSilent().then((user) => {
                console.log("Finished with the silent login");
            }).catch((error) => {
                newrelic.setCustomAttribute("silent-renew-failed", "Error while silent sign in of the user");
                console.log("Error while silent sign in of the user")
                /*this.userManager.getUser().then((user) =>
                    {
                        this.handleUser(user);
                    });*/
                sessionStorage.removeItem("SILENT_SIGN_IN");
            });
        });
    }

    signinRedirectCallback = () => {
        console.log("sign in redirect callback");
        this.UserManager.signinRedirectCallback().then((u) => {
            console.log("sign in redirect callback user ---->", u);
            this.getCipLoginFlow(u.access_token, u.id_token)
        }).catch((e)=> {
            console.log("sign in redirect callback error ---->",e);
            this.signinRedirect()
        });
    };

    signinSilentCallback = () => {
        console.log("sign in silent callback");
        this.UserManager.signinSilentCallback().then(async ()=> {
            let user = await this.getUser();
        });
    };

    getUser = async () => {
        console.log("get User called");
        const user = await this.UserManager.getUser();
        console.log("getUser", user);
        if (!user) {
            console.log("User is not logged in");
            return await this.UserManager.signinRedirectCallback();
        }
        return user;
    };

    parseJwt = (token) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };

    twoStepRedirect = () => {
        console.log("2sv redirect called", window.location.pathname)
        this.UserManager.signinRedirect({
            extraQueryParams: { //your params go here
                acr_values: 'LOA2'
            },
        });
    }

    signinRedirect = () => {
        console.log("sign in redirect called", window.location.pathname)
        this.UserManager.signinRedirect({});
    };

    /* navigateToScreen = () => {
         console.log("navigate to screen");
         window.location.replace("/smart-troubleshooting");
     };*/

    isAuthenticated = () => {
        console.log("isAuthenticated called");
        const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${CAIMAN_IDENTITY_CONFIG.authority}:${`smarthelp`}`))
        console.log("is authenticated", oidcStorage);
        return (!!oidcStorage && !!oidcStorage.access_token)
    };

    signinSilent = () => {
        console.log("sign in silent called", window.location.pathname)
        this.UserManager.signinSilent({});
    };

    createSigninRequest = () => {
        console.log("create sign in request");
        return this.UserManager.createSigninRequest();
    };

    // Check for performance and response timing for async/await and does we should handle 200 case instead of response.setHeader
    async getCipLoginFlow(access_token, id_token, isSilent) {
        console.log("getCipLoginFlow", access_token)
        sessionStorage.setItem('accessToken',access_token);
        let payload = {
            access_token: access_token,
            id_token: id_token
        }

        if (sessionStorage.hasOwnProperty("challenge2SV-c2c") && sessionStorage.getItem("challenge2SV-c2c") === "true") {
            sessionStorage.removeItem("challenge2SV-c2c");
            sessionStorage.setItem("challenge2SV-c2c-status", "success");
            window.location = '/smart-troubleshooting/callback-input';
            return;
        }

        let response = await fetch(SHARED_FLOW_URL, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload)
        }).then((res) => {
            return res.json();
        })
        console.log("Response ------->", response);   //assurance/sharedFlow

        if (isSilent !== true) {
            let deepLink = (sessionStorage && sessionStorage.hasOwnProperty("deeplink")) ? JSON.parse(sessionStorage.getItem("deeplink")) : {};
            console.log("deeplink - storage = ", deepLink);
            sessionStorage.setItem("isError", `${response.isError}`);

            if (response.isError === true) {
                newrelic.setCustomAttribute("error", "Shared Flow Call Redirects to Error page");
                window.location = "/smart-troubleshooting/error";
                return;
            }

            if(sessionStorage.hasOwnProperty("faulttracker") && sessionStorage.getItem("faulttracker")){
                window.location = JSON.parse(sessionStorage.getItem("faulttracker"));
                sessionStorage.removeItem("faulttracker");
                return;
            }
            if(deepLink !== null || deepLink !== undefined)
                redirectToDeeplinkFlow(deepLink)
            else {
                window.location = window.metadata.app.myTGetHelpRoute;
                return;
            }
        }
        // do nothing at all actually in the else condition because the else condition is the silent sign in flow
    };

    logout = async () => {
        let extraQueryParams = {
            "TargetResource": POST_LOGOUT_REDIRECT,
            "client_id": "smarthelp"
        };
        let post_logout_redirect_uri = POST_LOGOUT_REDIRECT;
        if (sessionStorage.getItem("SIGN_OUT_TIMED_OUT") && sessionStorage.getItem("SIGN_OUT_TIMED_OUT") === "true") {
            extraQueryParams = {
                "TargetResource": POST_TIMEOUT_LOGOUT_REDIRECT,
                "client_id": "smarthelp"
            }
            post_logout_redirect_uri = POST_TIMEOUT_LOGOUT_REDIRECT;
        }

        await fetch(SHARED_FLOW_URL, {
            method: 'DELETE',
            credentials: 'include'
        }).then((res) => {
            if (res.status === 204) {
                if (window.metadata && window.metadata.app.logoutUrl) {
                    window.location = window.metadata.app.logoutUrl;
                } else {
                    window.location = "https://myid.telstra.com/identity/idp/startSLO.ping?TargetResource=https%3A%2F%2Ffix.telstra.com";
                }
            }
        });

        this.UserManager.signoutRedirect({
            post_logout_redirect_uri,
            id_token_hint: sessionStorage.getItem("id_token"),
            extraQueryParams
        });
        this.UserManager.clearStaleState();
        sessionStorage.removeItem("SIGN_OUT_TIMED_OUT");
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            sessionStorage.clear();
            window.location.replace("/");
        });
        this.UserManager.clearStaleState();
    };
}
