import React, {Component} from "react";


/* istanbul ignore next*/
class DefaultRunTimeComponent extends Component{

    constructor(props) {
        super(props);
    }

    render(){
        return (
            <div style={{"display":"block"}}>Loading ....</div>
        )
    }
}

var RunTimeComponent = function(){
    const _instance = this;
    this.defaultComponent = DefaultRunTimeComponent;
    this.components = window.runtimeComponent || false;
    this.onResolve = [];
    /* istanbul ignore next*/
    if(!this.components) {
        Object.defineProperty(window, "runtimeComponent", {
            get: function () {
                return (_instance.components) ? _instance.components : {};
            },
            set: function (_v) {
                if (_instance.onResolve.length > 0) {
                    _instance.onResolve.forEach(_resolve => {
                        _resolve(_v);
                    });
                    _instance.onResolve = [];
                }
                _instance.components = _v;
            }
        });
    }
    this.getComponents = function(){
        return new Promise((resolve, reject)=>{
            /* istanbul ignore next*/
            if(_instance.components){
                resolve(_instance.components);
            }else{
                _instance.onResolve.push((components)=>{
                  resolve(components)
                });
            }
        });
    }
}



const runTimeComponent = new RunTimeComponent();

export default runTimeComponent;