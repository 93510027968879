import React from "react";
import { PropTypes } from "prop-types";
import BaseRunTimeContainer from '../common/BaseRunTimeContainer';
import needHelpImage from '../images/svg/need-help.svg';
import {deleteCookie} from "../common/util/CookieManager";
import BannerConfig from '../common/banner/config';
import appAnalytics from "../common/util/AppAnalytics";
import {AuthConsumer} from "./caiman/AuthProvider";
import ElectricityIcon from "../images/svg/electricity.svg";

const env = window.metadata.app.environment;

const bannerConfig = new BannerConfig({environment:env});

export default class CIPLogin extends BaseRunTimeContainer{

    constructor(props) {
        super(props, ["Button", "Banner"]);
        this.cipLogin = this.cipLogin.bind(this);
        this.loginButton = false;
        this.state.loginClicked = false;
        this.banner_config=bannerConfig.getConfig() || {bannerData:{loginScreen:{}}};
        console.log("config: ", this.banner_config);
        appAnalytics.send({
            eventType: "pageName",
            path: ["login"]
        });
        if (env === "prod")
            window.location.href = window.metadata.app.myTGetHelpRoute;
    }

    /* istanbul ignore next */
    cipLogin(){
        /* istanbul ignore next */window.usabilla_live("show"); //showing usabilla
        appAnalytics.send({
            eventType: "eventInfo",
            data:{
                "eventAction": "click",
                "eventType": "clickTrack",
                "eventCategory": "buttonClick",
                "eventName": "Start troubleshooting"
            },
        });
        // remove any existing value for deeplink storage as we are strictly relying on this in callback code i.e AuthService.js getCipLoginFlow function
        /* istanbul ignore next */sessionStorage.removeItem("deeplink");
        this.setState({loginClicked : true});
    }

    componentDidMount(){
        //this.loginButton.focus();
        deleteCookie(".telstra.com", "SESSION");
        window.usabilla_live("hide"); //hiding usabilla
    }

    render(){
        const {Button, Banner} = this.runTimeComponents;
        /* istanbul ignore next */
        if(this.state.loginClicked){
            return(
                <AuthConsumer>
                    {({ isAuthenticated, signinRedirect }) => {
                        if (isAuthenticated()) {
                            window.location = "/smart-troubleshooting/select-service";
                        } else {
                            signinRedirect();
                        }
                    }}
                </AuthConsumer>
            );
        }

        return (
            <section>
                <Banner config={this.banner_config.bannerData.loginScreen} className={"rt-margin-bottom-spacing1x"}/>
                <img src={needHelpImage}
                     className={"rt-margin-clean rt-margin-bottom-xxs"}
                     style={{"height":"17rem"}}
                     alt={"Telstra Self Service"}/>
                <h1 className={"rt-margin-clean rt-margin-bottom-s rt-heading-m"} tabIndex={this.props.tabGroup}>Need a hand?</h1>
                <p className={"rt-margin-clean rt-margin-bottom-xs rt-body-m-light"} tabIndex={this.props.tabGroup}>Welcome to our smart troubleshooting tool. It'll help you diagnose and resolve technical issues with Telstra services.</p>
                <p className={"rt-margin-clean rt-margin-bottom-xs rt-body-m-light"}>We'll take you on a step-by-step process to get your service working the way it should be.</p>
                <div><p className="rt-margin-clean rt-margin-bottom-s rt-body-m-light" style={{display:"grid", gridTemplateColumns: "2rem auto", color:"#0064D2" }}>
                    <img src={ElectricityIcon} className={"rt-body-s rt-margin-right-xs rt-margin-top-spacingHalf"} />
                    Tip: If you're having trouble with a connection, try restarting your device. It really can help!
                    </p>
                </div>
                <Button variant={"HighEmphasis"} preventOmniture={true} className={"rt-margin-clean rt-margin-bottom-spacing2x"} tabIndex={this.props.tabGroup} aria-label="Click to login with My telstra id" onClick={this.cipLogin} ref={node=>{this.loginButton = node;}}>Start troubleshooting</Button>
            </section>
        )
    }
}

CIPLogin.propTypes = {
    tabGroup: PropTypes.number
};
CIPLogin.defaultProps = {
    tabGroup: 0
};
