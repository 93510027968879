import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<defs>
		<path id="id-15a" d="M12 1.4c5.85.011 10.589 4.75 10.6 10.6 0 5.854-4.746 10.6-10.6 10.6-5.854 0-10.6-4.746-10.6-10.6C1.4 6.146 6.146 1.4 12 1.4Zm0 1.2a9.4 9.4 0 1 0 0 18.8 9.41 9.41 0 0 0 9.4-9.4A9.4 9.4 0 0 0 12 2.6Zm.87 5.23v8.33h-1.5V9.6l-1.84 1.33V9.3l2.05-1.47h1.29Z"/>
	</defs>
	<g fill="none" fillRule="evenodd">
		<mask id="id-16b" fill="#fff">
			<path d="M12 1.4c5.85.011 10.589 4.75 10.6 10.6 0 5.854-4.746 10.6-10.6 10.6-5.854 0-10.6-4.746-10.6-10.6C1.4 6.146 6.146 1.4 12 1.4Zm0 1.2a9.4 9.4 0 1 0 0 18.8 9.41 9.41 0 0 0 9.4-9.4A9.4 9.4 0 0 0 12 2.6Zm.87 5.23v8.33h-1.5V9.6l-1.84 1.33V9.3l2.05-1.47h1.29Z"/>
		</mask>
		<path d="M12 1.4c5.85.011 10.589 4.75 10.6 10.6 0 5.854-4.746 10.6-10.6 10.6-5.854 0-10.6-4.746-10.6-10.6C1.4 6.146 6.146 1.4 12 1.4Zm0 1.2a9.4 9.4 0 1 0 0 18.8 9.41 9.41 0 0 0 9.4-9.4A9.4 9.4 0 0 0 12 2.6Zm.87 5.23v8.33h-1.5V9.6l-1.84 1.33V9.3l2.05-1.47h1.29Z" fill="#414141" fillRule="nonzero"/>
		<g fill="#414141" mask="url(#id-16b)">
			<path d="M0 0h24v24H0z"/>
		</g>
	</g>
</svg>); 
 }; 
 export default SVG;