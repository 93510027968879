import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="#414141" fillRule="evenodd" transform="translate(1 4)">
		<path d="M11 0A14.64 14.64 0 0 0 .58 4.31l.84.85c5.296-5.279 13.864-5.279 19.16 0l.84-.85A14.64 14.64 0 0 0 11 0Z"/>
		<path d="m3.58 7.09.84.85a9.31 9.31 0 0 1 13.16 0l.84-.85c-4.102-4.089-10.738-4.089-14.84 0zm3 2.97.84.85a5.08 5.08 0 0 1 7.16 0l.84-.85a6.25 6.25 0 0 0-8.84 0z"/>
		<circle cx="11" cy="13.65" r="1.75"/>
	</g>
</svg>); 
 }; 
 export default SVG;