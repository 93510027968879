import AppLog from "../common/util/AppLog";
const log = new AppLog("AppClient");


const getAppBFF = ()=>{
    return (window.metadata && window.metadata.appBff)?window.metadata.appBff:/* istanbul ignore next*/"";
}
class WorkflowClient {
    constructor(){
        log.info("Create instance of WorkflowClient");

    }

   async fetchDeepLinkData(signature, token){
       log.info("Fetching DeepLink data");
        const header = "";
        let headerData = Object.assign({'Content-Type': 'application/json'}, header || {});

        let data = await fetch(getAppBFF()+'/selfservice/deeplink', {
            credentials: 'include',
            method: 'post',
            body: JSON.stringify({"signature": signature, "token": token})
        })
            .then(async (response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return await response.json();
                } else {
                    throw response;
                }
            });

        return data;
    }
    async callToWorkFlowApi(payload){
        let data = await fetch(getAppBFF() + '/selfservice/nbn/workflow', {
            // credentials: 'include',
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload)
        })
            .then(async (response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return await response.json();
                } else {
                    throw response;
                }
            });
        return data;
    }

    async fetchWorkflow(payload) {
        log.info("Workflow Initializing");
        return await this.callToWorkFlowApi(payload);
    }


}
const workflowClient = new WorkflowClient();

export default workflowClient;




export const fetchData = function(settings, data, callBack, callBackError) {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        /* istanbul ignore next  */
        if (this.readyState === 4 && this.status === 200) {
            callBack(JSON.parse(this.responseText));
        }else if (this.readyState === 4 && callBackError){
            callBackError(this.status, this.responseText);
        }
    };
    xhttp.open(settings.method || "GET", settings.url, true);
    if(settings.headers){
        for(var header in settings.headers){
            xhttp.setRequestHeader(header, settings.headers[header]);
        }
    }
    if(settings.secure){
        xhttp.withCredentials = true;
    }
    xhttp.send(JSON.stringify(data));
}

export const fetchWorkflow = function(data, callBack, callBackError) {
    // const newrelic = window.newrelic || {
    //     setCustomAttribute: () => {}
    // };

    //workflowLogger.getInstance("workflow-service").info("request", data);
    fetchData({
            url: getAppBFF() + "/selfservice/nbn/workflow",
            method: "POST",
            headers:{
                "Content-Type": "application/json"
            }
        },
        data,
        (res)=>{
            //workflowLogger.getInstance("workflow-service").info("response", res);
            /* istanbul ignore next*/
            if(callBack){
                callBack(res);
            }
        },
        (status, res)=>{
            //workflowLogger.getInstance("workflow-service").info("response", res);
            // newrelic.setCustomAttribute("nbn-workflow-error-ui", JSON.stringify({
            //     status: status,
            //     selected_issue: cache.getProperty(APP_CACHE.SELECTED_ISSUE),
            //     diagnostic_result: cache.getProperty(APP_CACHE.DIAGNOSTIC_RESULTS),
            //     request_corrId: (data?data.correlation_id:undefined)
            // }));

            /* istanbul ignore next*/
            if(callBackError){
                callBackError(status, res);
            }
        });
}




export const getLatestStep = function(data, callBack, callBackError) {
    if (data.correlation_id) {
        var _request = {
            "type": "get_latest_step",
            "correlation_id": data.correlation_id,
            "all_steps": false
        };
        /* istanbul ignore next*/
        if (data.steps) {
            _request.step = {
                "id": data.steps.id,
                "activity": data.steps.activity
            };
        }
        fetchWorkflow(
            _request,
            /* istanbul ignore next*/
            function (json) {
                if (json.eta) {
                    setTimeout(function () {
                        getLatestStep(json, callBack, callBackError);
                    }, parseInt(json.eta, 10) / 4);
                } else {
                    callBack(json);
                }
            },
            callBackError
        )
    } else {
        return
    }
}

export const fetchWorkflowAsync = function(data, callBack, callBackError, etaOverwrite, callBackEta) {
    fetchWorkflow(data, (res)=>{
        // var etaOverwrite=3000;
        /* istanbul ignore next*/
        var eta = (etaOverwrite)?parseInt(res.eta,10)/etaOverwrite:parseInt(res.eta,10);
        if(res.eta){
            /* istanbul ignore next*/
            if(callBackEta)callBackEta(res.eta);
            setTimeout(()=>{
                var _request = {
                    all_steps : false,
                    correlation_id : res.correlation_id,
                    type:"get_latest_step"
                };
                /* istanbul ignore next*/
                if(res.steps){
                    _request.step={
                        "id": res.steps.id,
                        "activity": res.steps.activity
                    };
                    if(res.steps.detail)_request.step.detail=res.steps.detail;
                    if(res.steps.context)_request.step.context=res.steps.context;
                    //@TODO Direct hard coded , need to review with get help why not this is part of response?
                    //Please do not add any more case options - this is tactical and NOT the right way to do things
                    // Take to SNOW to get them to abstract this mess away from us!
                    switch (res.steps.activity) {
                        case    "appt_slot_nbn":
                            _request.waiting_for = "booking_confirmation";
                            break;
                        default:
                    }
                }
                fetchWorkflowAsync(_request, callBack, callBackError, etaOverwrite);
            },3000);
        }else{
            callBack(res);
        }
    }, callBackError)
}

export const fetchDataAsync = function(settings, data, callBack, callBackError, etaOverwrite) {
    fetchData(settings, data, (res)=>{
        /* istanbul ignore next*/
        workflowClient.parseIntNvl(etaOverwrite, ()=>{workflowClient.parseIntNvl(res.eta, 1000)});
        var eta = (etaOverwrite)?parseInt(res.eta, 10)/etaOverwrite:parseInt(res.eta, 10);
        if(res.eta){
            setTimeout(()=>{
                fetchWorkflowAsync(data, callBack, callBackError, etaOverwrite, etaOverwrite);
            },eta);
        }else{
            callBack(res);
        }
    }, callBackError)
}

