import AllowServiceList from "./AllowServiceList";
import icons from "../../common/IconsList";

const GenerateServiceType = (CONSTANTS)=>{
    const knownServices = {};
    knownServices[CONSTANTS.SERVICE_NAME.ADSL] = {
        title: AllowServiceList.INTERNET_TITLE,
        icon: icons.modemIcon
    };
    knownServices[CONSTANTS.SERVICE_NAME.NBN] = {
        title: AllowServiceList.INTERNET_TITLE,
        icon: icons.modemIcon
    };
    knownServices[CONSTANTS.SERVICE_NAME.CABLE] = {
        title: AllowServiceList.INTERNET_TITLE,
        icon: icons.modemIcon
    };
    knownServices[CONSTANTS.SERVICE_NAME.LANDLINE] = {
        title: AllowServiceList.PHONE_TITLE,
        icon: icons.productPhoneIcon
    };
    knownServices[CONSTANTS.SERVICE_NAME.TELEPHONE_LINE] = {
        title: AllowServiceList.PHONE_TITLE,
        icon: icons.productPhoneIcon
    };
    knownServices[CONSTANTS.SERVICE_NAME.INTERNET] = {
        title: AllowServiceList.INTERNET_TITLE,
        icon: icons.modemIcon
    };
    knownServices[CONSTANTS.SERVICE_NAME.EMAIL] = {
        title: AllowServiceList.EMAIL_TITLE,
        icon: icons.emailIcon
    };
    knownServices[CONSTANTS.SERVICE_NAME.MOBILE] = {
        title: AllowServiceList.MOBILE_TITLE,
        icon: icons.mobileIcon
    };
    knownServices[CONSTANTS.SERVICE_NAME.PREPAID_MOBILE] = {
        title: AllowServiceList.PREPAID_TITLE,
        icon: icons.mobilePrepaidIcon
    };
    knownServices[CONSTANTS.SERVICE_NAME.DAVINCI_MOBILE] = {
        title: AllowServiceList.MOBILE_TITLE,
        icon: icons.mobileIcon
    };
    return knownServices;
}

export default GenerateServiceType;
