import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="none" fillRule="evenodd" stroke="#0A0B09">
		<path d="m6.394 9.745.563.031 1.116.386.293.388 1.122.424.892.458-.408.962-.265 1.139-.879.424-.492 1.19-.448.027.142.43-.509.409-.161 1.044.01.47.328.226-.373.133-.647-.532-.525-4.104-.767-.279-.91-1.122.393-1.059-.221-.576.433-.522" strokeWidth=".907"/>
		<path d="M3.559 8.687c-.625 1.299-.959 2.26-.959 3.798 0 5.476 4.439 9.916 9.915 9.916 5.476 0 9.916-4.44 9.916-9.916 0-2.835-1.206-5.372-3.115-7.176m-2.612-1.801a9.847 9.847 0 0 0-4.189-.938c-3.017 0-5.201 1.242-7.017 3.369" strokeWidth="1.199"/>
		<path d="m11.114 2.659-1.447.471 1.21 1.143-.332.745s1.063 2.312 1.109 2.274c.046-.039.118 1.4.118 1.4l.706-.852s2.33 1.327 2.39 1.276c.061-.051 1.144-.393 1.144-.393l-.181-.676 1.068-.329.383.455 3.84.074" strokeWidth=".907"/>
		<path d="M6.415 7.282a1.727 1.727 0 0 0-.157-.495c-.414-.843-1.421-1.196-2.248-.789-.827.407-1.162 1.42-.748 2.263.079.16.179.303.297.426.464.584 2.538 1.555 2.538 1.555s.497-2.235.318-2.96Zm0 0a1.727 1.727 0 0 0-.157-.495c-.414-.843-1.421-1.196-2.248-.789-.827.407-1.162 1.42-.748 2.263.079.16.179.303.297.426.464.584 2.538 1.555 2.538 1.555s.497-2.235.318-2.96Zm-.924-.163a.835.835 0 1 1-1.5.735.835.835 0 0 1 1.5-.735Zm0 0a.835.835 0 1 1-1.5.735.835.835 0 0 1 1.5-.735Zm14.447-3.135c.049-.162.075-.335.073-.514-.009-.939-.763-1.693-1.685-1.684-.922.008-1.662.776-1.653 1.715.002.18.03.352.083.513.166.728 1.615 2.501 1.615 2.501s1.415-1.8 1.567-2.531Zm-.762-.547a.834.834 0 1 1-1.669.017.834.834 0 0 1 1.669-.017Z" strokeWidth=".567"/>
		<path d="m12.122 20.488-1.859 1.362s5.049 2.493 9.858-2.9l-1.795-.55-1.735 1.439-1.501-.528-.89-.444" strokeWidth=".907"/>
		<path d="M14.256 18.656c.049-.162.074-.335.073-.514-.01-.939-.763-1.693-1.685-1.685-.922.009-1.663.778-1.654 1.716.002.179.032.352.083.513.166.729 1.616 2.501 1.616 2.501s1.415-1.8 1.567-2.531Zm-.762-.547a.836.836 0 0 1-1.67.015.834.834 0 0 1 1.67-.015Z" strokeWidth=".567"/>
	</g>
</svg>); 
 }; 
 export default SVG;