import React from "react";
import BaseRunTimeContainer from "../BaseRunTimeContainer";
import appAnalytics from "../util/AppAnalytics";


export default class CallMeNowButton extends BaseRunTimeContainer {

    constructor(props) {
        super(props, ["Button"]);
    }

    onClickHandler = () => {
        appAnalytics.send({
            eventType: "eventInfo",
            data:{
                "eventAction": "click",
                "eventType": "clickTrack",
                "eventCategory": "buttonClick",
                "eventName": "Call me now"
            },
        });
        window.location = "/smart-troubleshooting/callback-input";
    }

    render() {
        const {isCallbackAvailable} = this.props;
        const {Button} = this.runTimeComponents;
        const callbackButton = <div><Button preventOmniture={true} className="rt-margin-bottom-spacing5x" variant="MediumEmphasis"
                                            onClick={this.onClickHandler}>Call me now</Button></div>;

        return isCallbackAvailable ?
            callbackButton : "";

    }
}
