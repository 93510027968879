import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="23pt" width="32" viewBox="0 0 24 23" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<path style={{"stroke":"none","fillRule":"nonzero","fill":"#000","fillOpacity":"1"}} d="M7.23 3.629a.842.842 0 0 0-.414.375c-.175.39.004.812.418.976.059.024.22.047.442.055.75.047 1.293.215 1.886.594.438.277.766.594 1.063 1.023.375.543.563 1.106.605 1.79.012.203.04.374.063.44.05.141.21.313.363.388.098.046.149.058.344.058.207 0 .238-.008.36-.07a.765.765 0 0 0 .34-.38c.058-.148.062-.503.007-.89-.156-1.136-.66-2.117-1.5-2.93-.824-.792-1.867-1.285-3.047-1.437-.332-.043-.797-.039-.93.008Zm0 0"/>
	<path style={{"stroke":"none","fillRule":"nonzero","fill":"#000","fillOpacity":"1"}} d="M6.758 5.715a.69.69 0 0 0-.371.2.692.692 0 0 0-.012.991c.164.16.27.2.64.239.356.035.508.07.75.183.368.172.684.465.872.809.136.258.195.46.218.773.016.238.032.293.098.422.168.324.543.469.914.36a.644.644 0 0 0 .274-.18c.207-.196.238-.293.218-.688-.074-1.644-1.421-2.969-3.171-3.12-.29-.024-.282-.024-.43.01Zm0 0"/>
	<path style={{"stroke":"none","fillRule":"nonzero","fill":"#000","fillOpacity":"1"}} d="M6.55 8.645a.82.82 0 0 0-.487.414c-.047.105-.051.136-.06 1.273L5.997 11.5H4.781c-1.355 0-1.379.004-1.707.152-.25.118-.539.391-.656.63-.172.335-.16.152-.16 3.175 0 2.355.004 2.746.035 2.863.137.516.605.946 1.152 1.059.254.05 16.856.05 17.11 0 .547-.113 1.015-.543 1.152-1.059.031-.117.035-.508.035-2.863 0-3.023.012-2.84-.16-3.176-.117-.238-.406-.511-.656-.629-.36-.164.21-.152-6.95-.152h-6.46L7.5 9.164l-.066-.129a.807.807 0 0 0-.36-.344c-.11-.054-.41-.082-.523-.046Zm13.708 6.812v2.508H3.742v-5.016h16.516Zm0 0"/>
	<path style={{"stroke":"none","fillRule":"nonzero","fill":"#000","fillOpacity":"1"}} d="M8.457 14.938c-.406.12-.527.624-.223.902.235.215.602.2.809-.031.215-.23.195-.547-.047-.754a.53.53 0 0 0-.539-.117ZM10.703 14.938a.527.527 0 0 0-.316.777.572.572 0 0 0 .988-.016c.227-.426-.2-.906-.672-.761ZM12.984 14.926c-.32.097-.488.379-.398.672a.56.56 0 0 0 .812.324.526.526 0 0 0-.007-.942c-.118-.058-.313-.085-.407-.054ZM15.207 14.93a.752.752 0 0 0-.203.125c-.242.207-.262.523-.047.754.34.37.977.14.98-.356 0-.242-.16-.45-.406-.515a.51.51 0 0 0-.324-.008Zm0 0"/>
</svg>); 
 }; 
 export default SVG;