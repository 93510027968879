import cache, {APP_CACHE} from "../util/cacheManager";
import moment from "moment-timezone";
import React from "react";

function getBFFEndpointForAddress() {
    return (window.metadata && window.metadata.appBff) ? window.metadata.appBff :/* istanbul ignore next*/"";
}

function whetherItsNBNService(serviceId, nbnServiceIds) {
    try {
        return !!(nbnServiceIds && nbnServiceIds.includes(serviceId));
    } catch (e) {
        return false;
    }
}

function renderWaitTimeAlert(Alert,callbackDetails) {
    if (callbackDetails && !isNaN(callbackDetails.waitTime)) {
        let waitTimeInMinutes = Math.ceil(callbackDetails.waitTime / 60);
        return waitTimeInMinutes > 0 ? <div><Alert>
            <p><b>You could be on hold for {waitTimeInMinutes} {waitTimeInMinutes === 1 ? "min" : "mins"}</b></p>
        </Alert></div> : "";
    }
}

async function getCallbackDetails(serviceId, selectedService) {
    let url = window.metadata.dependencies["click-to-connect-api"].apiUrl;
    url = url+"intentOptions?accountUUID="+selectedService.accountUUID+"&cac="+selectedService.cac+"&reason=Fault&service=NBN&segment=Consumer&auth=Y"
    return await fetch(url, {
        method: 'get',
        credentials: 'include',
        headers: getC2cHeaders()
    }).then(async (response) => response.json())
        .then(async (result) => {
            if (result && result.channelsAvailable && result.channelsAvailable.voice === true) {
                let callbackDetails = {
                    serviceId: serviceId,
                    waitTime: result.waitTime,
                    contactId: result.contactId
                }
                cache.saveProperty(APP_CACHE.CALLBACK_DETAILS, callbackDetails);
                return isAgentServiceAvailable(result)
            } else {
                return false;
            }
        }).catch(error => {
            console.log(error)
            window.location = "/error";
        })
}

function isAgentServiceAvailable(result) {
    try {
        let weekday = moment().isoWeekday();
        let voiceHoursOfOperation = result.voice.hoursOfOperation;
        let hours, minutes;
        if (moment && moment.tz) {
             hours = moment.tz("Australia/Melbourne").format("HH");
             minutes = moment.tz("Australia/Melbourne").format("mm");
        }
        voiceHoursOfOperation.currentTime = (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);
        if (weekday) {
            if (weekday === 7) {
                return isServiceOpenOnGivenDay(voiceHoursOfOperation.weekendSun, voiceHoursOfOperation.currentTime, weekday);
            } else if (weekday === 6) {
                return isServiceOpenOnGivenDay(voiceHoursOfOperation.weekendSat, voiceHoursOfOperation.currentTime, weekday);
            } else {
                return isServiceOpenOnGivenDay(voiceHoursOfOperation.weekday, voiceHoursOfOperation.currentTime, weekday);
            }
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }
}

function isServiceOpenOnGivenDay(voiceHoursOfOperationDetails, currentTime) {
    if (voiceHoursOfOperationDetails && voiceHoursOfOperationDetails.isOpen === true) {
        return isAgentWorkingAtTheMoment(voiceHoursOfOperationDetails, currentTime);
    } else {
        return false;
    }
}

function isAgentWorkingAtTheMoment(voiceHoursOfOperationDetails, currentTime) {
    let format = 'HHmm';
    let currentTimeFormat = "HH:mm";
    let openTime = adjustHourFormat(voiceHoursOfOperationDetails.openTime);
    let closeTime = adjustHourFormat(voiceHoursOfOperationDetails.closeTime);

    return moment(currentTime, currentTimeFormat).isBetween(moment(openTime, format), moment(closeTime, format))
        || moment(currentTime, currentTimeFormat).isSame(moment(openTime, format)
            || moment(currentTime, currentTimeFormat).isSame(moment(closeTime, format)));
}

function adjustHourFormat(time) {
    if (time && time.length === 3) {
        return "0" + time
    } else {
        return time
    }
}

function getC2cHeaders() {
    return {
        "Accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "source-system":"FixTelstra"
    };
}

export {getCallbackDetails, whetherItsNBNService, isAgentServiceAvailable,getBFFEndpointForAddress,getC2cHeaders,renderWaitTimeAlert};
