import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="#414141" fillRule="evenodd">
		<path d="M2.9 3.4v17.2h18.2V3.4H2.9Zm17 16H4.1V4.6h15.8v14.8Z" fillRule="nonzero"/>
		<path d="M5.5 6.4h13v1.2h-13zM5.5 9.4h13v1.2h-13zM5.5 12.4h6v1.2h-6zM5.5 15.4h6v1.2h-6z"/>
		<path d="M13.4 16.6h5.2v-4.2h-5.2v4.2Zm1.2-3h2.8v1.8h-2.8v-1.8Z" fillRule="nonzero"/>
	</g>
</svg>); 
 }; 
 export default SVG;