import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 15 25" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="#414141" fillRule="evenodd" transform="translate(0 .5)">
		<path d="M.4.4v23.2h14.2V.4H.4Zm13 22H1.6V1.6h11.8v20.8Z" fillRule="nonzero"/>
		<path d="M5.5 2.4h4v1.2h-4z"/>
		<circle cx="7.5" cy="20.5" r="1"/>
	</g>
</svg>); 
 }; 
 export default SVG;