import BaseClient from "../../common/client/BaseClient";
import AppLog from "../../common/util/AppLog";
import cache, {APP_CACHE} from "../../common/util/cacheManager";
import appAnalytics from "../../common/util/AppAnalytics";
import {getMultipleStateOutages} from "../../common/util/outagesUtil";

const log = new AppLog("AppClient");

const getAppBFF = ()=>{
    return (window.metadata && window.metadata.appBff)?window.metadata.appBff:/* istanbul ignore next*/"";
}

class IdentityClient {
    constructor(){
        log.info("Create instance of IdentityClient");
        cache.deleteProperty(APP_CACHE.FAULT_SERVICES);
        appAnalytics.send({
            eventType: "pageName",
            path: ["authenticated","IdentityClient"]
        });
    }

    fetchUserData(){
        log.info("UI requested /user call for Fetching user data");
        return new Promise((resolve, reject)=>{
            BaseClient(window.metadata.app.userUrl, {
                    credentials: 'include'
                })
                .then(response=>{
                    switch(response.status){
                        case 200:
                            return response.json();
                            /* istanbul ignore next*/break;
                            /* istanbul ignore next*/default:
                    }
                })
                .then(json=>{
                    json.services = Array.isArray(json.services)? json.services.sort((a, b)=>{
                        return a.displayOrder - b.displayOrder;
                    }) : json.services;

                    json.incidents = Array.isArray(json.incidents)?json.incidents:"";
                    var legacyServiceList = [];
                    var nbnServiceList = [];
                    var adslServiceList=[];
                    var faultIncidentList =[];
                    json.services.forEach(function(service){
                        /* istanbul ignore next*/
                        if(service.productName === "Internet Service" && ["cable", "pstn"].includes((service.technologyType.toString()).toLowerCase())){
                            legacyServiceList.push(service.serviceId, service.adborId);
                        }
                        /* istanbul ignore next*/
                        else if(service.productName === "Internet Service" && !["adsl", "cable", "pstn"].includes((service.technologyType.toString()).toLowerCase())){
                            nbnServiceList.push(service.serviceId, service.adborId);
                        }
                        else if(service.productName === "Internet Service" && ["adsl"].includes((service.technologyType.toString()).toLowerCase())){
                            adslServiceList.push(service.serviceId, service.adborId);
                        }
                    });

                    getMultipleStateOutages(json.services).then(o => {
                        log.info("Fetched the outages for the state(s)", cache.getProperty(APP_CACHE.SERVICE_STATES));
                    })

                    console.log("json.incidents: --> ", json.incidents)
                    json.incidents && json.incidents.forEach(function (incident) {
                        /* istanbul ignore next*/
                        faultIncidentList.push(incident);
                    });
                    cache.saveProperty(APP_CACHE.LEGACY_SERVICES, legacyServiceList);
                    cache.saveProperty(APP_CACHE.NBN_SERVICES, nbnServiceList);
                    cache.saveProperty(APP_CACHE.ADSL_SERVICES, adslServiceList);
                    cache.saveProperty(APP_CACHE.FAULT_SERVICES, faultIncidentList);

                    if (json.registeredMobileNumber) {
                        cache.saveProperty(APP_CACHE.REGISTERED_PHONE_NUMBER, json.registeredMobileNumber.replace("+61", "0"));
                    }
                    cache.saveProperty(APP_CACHE.CONTACT_ID, json.contactId);
                    if(!(window?.digitalData?.user && window.digitalData.user[0]?.profile[0]?.profileInfo?.userContactUUID)){
                        window.digitalData.user = [{
                            profile: [{
                                profileInfo: {
                                    username: json.username,
                                    userContactUUID: json.contactId,  // Salesforce Contact UUID
                                }
                            }]
                        }];
                    }
                    window.userDetail = {
                        firstName: json.firstName,
                        lastName: json.lastName
                    };
                    resolve(json);
                })
                .catch(e=>{
                    reject({
                        error: (e && e.message)?e.message: "Unknown error"
                    })
                });
        })
    }

    fetchAccountData(serviceViewId){
        log.info("Fetching fetchAccount data passing serviceViewId", serviceViewId);
        return new Promise((resolve, reject)=>{
            BaseClient(getAppBFF()+'/selfservice/account?'+
                'serviceViewId=' + serviceViewId, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            })
                .then(response=>{
                    switch(response.status){
                        case 200:
                            return response.json();
                            /* istanbul ignore next*/break;
                            /* istanbul ignore next*/default:
                    }
                })
                .then(json=>{
                    resolve(json);
                })
                .catch(e=>{
                    reject({
                        error: (e && e.message)?e.message: "Unknown error"
                    })
                    log.info("UI error for account call", e.message);

                });
        })
    }
}

const identityClient = new IdentityClient()

export default identityClient;
