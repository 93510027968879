import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import App from "./common/App";

ReactGA.initialize(window.metadata.app.gaTrackingId || 'UA-98503385-1');

window.addEventListener("load",(e)=>{
    ReactDOM.render(
        <App/>,
        document.querySelector('main')
    );
});

