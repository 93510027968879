import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<circle cx="9.34" cy="19.6" r="1"/>
	<path d="m16.87 4-1.34 1.34L14.19 4l-.85.85 1.34 1.34-1.34 1.34.85.85 1.34-1.34 1.34 1.34.85-.85-1.34-1.34 1.34-1.34-.85-.85z"/>
	<path d="M15.53 1a5.19 5.19 0 0 0-3.75 1.61H3.24v19.82h12.2v-11h.09a5.23 5.23 0 1 0 0-10.43Zm-1.29 18v2.23h-9.8V3.77h6.49a5.3 5.3 0 0 0-.54 1.63H7.72v1.2h2.63a5.2 5.2 0 0 0 3.89 4.63Zm1.29-8.79a4 4 0 1 1 4-4 4 4 0 0 1-4 4Z"/>
</svg>); 
 }; 
 export default SVG;