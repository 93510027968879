import React from 'react';

export default class Config {
    bannerData= {
        loginScreen : {
            content: [<h3 className="rt-heading-xxs">Planned system maintenance</h3>,
                <p className="rt-body-xs-dark">Friday 29 May 2020 10:00PM AEST to Saturday 30 May 2020 5:00AM AEST</p>,
                <p>Some services may not be available during this time</p>],

            startDate: "2020-05-29 12:00:00",
            endDate: "2020-05-30 05:00:00",
            enabled: true
        }
    }
}
