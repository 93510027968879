import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="#0A0B09" fillRule="evenodd">
		<path d="M4 6.599h16V5.4H4zM4 12.599h16V11.4H4zM4 18.599h16V17.4H4z"/>
	</g>
</svg>); 
 }; 
 export default SVG;