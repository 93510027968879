
export default class AppLog {
    constructor(classType) {
        this.appName = "SelfService";
        this.classType = classType;
        this.timeStyle = "background: gray; color: white; display: block; padding: 2px 0px; line-height: 24px; font-size: 20px;";
        this.classStyle = "background: gray; color: black; display: block; padding: 2px 0px; line-height: 24px; font-size: 20px;";
        this.infoStyle =  "background: #2c74d3; color: white; display: block; padding: 2px 10px; line-height: 24px; font-size: 20px;";
        this.warnStyle =  "background: darkorange; color: white; display: block; padding: 2px 10px; line-height: 24px; font-size: 20px;";
        this.errorStyle =  "background: red; color: white; display: block; padding: 2px 10px; line-height: 24px; font-size: 20px;";
        console.log( window.metadata.app.log);
    }

    getConfig(){
        return  window.metadata.app.log || {
            logLevels:["warn","info","error"]
        };
    }
    /*istanbul ignore next*/
    info(param1, param2) {
        if(this.getConfig().logLevels.indexOf && this.getConfig().logLevels.indexOf("info")>-1 ) {
            if (this.getConfig().LogTrace) {
            }
        }
    }

    warn(param1, param2){
        if(this.getConfig().logLevels.indexOf && this.getConfig().logLevels.indexOf("warn")>-1 ) {
            console.log('%c' + new Date().toLocaleTimeString('en-US') + '%c[' + this.classType + ']' + '%c' + param1, this.timeStyle, this.classStyle, this.warnStyle);
            if (this.getConfig().LogTrace) {
                console.log(param2);
            }
        }
    }

    error(param1, param2) {
        if(this.getConfig().logLevels.indexOf && this.getConfig().logLevels.indexOf("error")>-1 ) {
            console.log('%c' + new Date().toLocaleTimeString('en-US') + '%c[' + this.classType + ']' + '%c' + param1, this.timeStyle, this.classStyle, this.errorStyle);
            if (this.getConfig().LogTrace) {
                console.log(param2);
            }
        }
    }
}
