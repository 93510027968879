import fetch from "isomorphic-fetch";
import cache, {APP_CACHE} from "./cacheManager.js";

export const getMultipleStateOutages = async (services) => {

    if (services === null || services === undefined || services.length ===0) {
        return [];
    }

    let adBordIds = new Set(); // used set to maintain uniqueness
    services.forEach(s => {
        if (s.adborId) {
            adBordIds.add(s.adborId)
        }
    })
    cache.saveProperty(APP_CACHE.ADBOR_IDS, JSON.stringify(Array.from(adBordIds)));

    let getSingleStatePromises = [];
    if (adBordIds.size > 0) {
        adBordIds.forEach(adBorId => {
            getSingleStatePromises.push(getStateForOutageCheck(adBorId));
        });
    }
    let stateSets = new Set();
    await Promise.all(getSingleStatePromises).then(responses => {
        responses.forEach(res => {
            if (res.state) {
                stateSets.add(res.state)
            }
        })
    });

    cache.saveProperty(APP_CACHE.SERVICE_STATES, JSON.stringify(stateSets)); // may be required later
    let stateOutagesPromises = [];
    stateSets.forEach(s => {
        stateOutagesPromises.push(getOutagesForState(s));
    })

    let outages = [];
    await Promise.all(stateOutagesPromises).then(res => {
        res.forEach(r => {
            if (r.massOutage) {
                outages.push(...r.massOutage)
            }
        })
    })

    cache.saveProperty(APP_CACHE.STATE_OUTAGES,outages);
    return outages;

}

export const getStateForOutageCheck = (adborId) => {
    return new Promise((resolve, reject)=>{
        fetch( window.metadata.dependencies["selfservice-account-address"].apiUrl+
            '?adborId=' + adborId , {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
            .then(response=>{
                switch(response.status){
                    case 200:
                        return response.json();
                        /* istanbul ignore next*/
                        break;
                    /* istanbul ignore next*/
                    default:
                }
            })
            .then(json=>{
                resolve(json);
            })
            .catch(e=>{
                reject({
                    error: (e && e.message)?e.message:
                        /* istanbul ignore next*/"Unknown error"
                })
            });
    });
}

export const getOutagesForState = (state) => {
    let url = window.metadata.dependencies["selfservice-mass-outage-api"].apiUrl+"?state="+state
    return new Promise((resolve, reject)=>{
        fetch( url, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        })
            .then(response=>{
                switch(response.status){
                    case 200:
                        return response.json();
                        /* istanbul ignore next*/
                        break;
                    /* istanbul ignore next*/
                    default:
                }
            })
            .then(json=>{
                resolve(json);
            })
            .catch(e=>{
                reject({
                    error: (e && e.message)?e.message:
                        /* istanbul ignore next*/"Unknown error"
                })
            });
    });
}
