import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="#414141" fillRule="evenodd" transform="translate(5)">
		<path d="M12.6 18.6v-8.2h-3v-5H5.4v3h-3v10.2h10.2Zm-1.2-7v5.8H9.6v-5.8h1.8Zm-4.8-5h1.8v10.8H6.6V6.6Zm-3 10.8V9.6h1.8v7.8H3.6Z" fillRule="nonzero"/>
		<path d="M.4.4v23.2h14.2V.4H.4Zm13 22H1.6V1.6h11.8v20.8Z" fillRule="nonzero"/>
		<path d="M5.5 2.4h4v1.2h-4z"/>
		<circle cx="7.5" cy="20.5" r="1"/>
	</g>
</svg>); 
 }; 
 export default SVG;