class CacheManager {

    saveProperty(name, value, includeProps){
        sessionStorage.setItem(name, JSON.stringify(value), includeProps);
    }
    getProperty(name, _props){
        var retObj = {};
        if(_props && _props[name])
            return _props[name]
        else if(sessionStorage.hasOwnProperty(name)){
            try{
                retObj = JSON.parse(sessionStorage.getItem(name));
            }catch(e){

            }
            return retObj;
        }else
            return retObj;
    }
    hasProperty(name, _props){
        if((_props && _props[name]) || (sessionStorage.hasOwnProperty(name)))
            return true;
        else
            return false;
    }
    deleteProperty(name){
        sessionStorage.removeItem(name);
    }

}

const cache = new CacheManager();

export default cache;

export const APP_CACHE = {
    SELECTED_SERVICE: "selectedService", // All properties related to selected service
    DIAGNOSTIC_RESULTS: "diagnosticResults", // All properties related to outage/diagnostic results for current test
    SELECTED_ISSUE: "selectedIssue", // All properties related to select issue including workflow, diagnostic name etc.
    SNOW_CORRELATION_ID: "correlationId",
    WORKFLOW_CORRELATION_ID: "correlationId",
	USER_SERVICES: "userServices", // All user services
    MOBILE_CALLUS: "mobileTransferToAgent",
    LEGACY_SERVICES: "legacyServices", // All Internet Services related to legacy
    ADBOR_IDS: "adbor_ids",
    SERVICE_STATES: "service_states",
    STATE_OUTAGES: "state_outages",
    NBN_SERVICES: "nbnServices", //All Internet Services related to nbn
    FAULT_TRACKER_HOME: "postLoginFaulttracker",
    MASS_OUTAGE_TITLE: "massoutagetitle",
    MASS_OUTAGE_DESCRITPION: "massoutagedescription", //All ADSL services
    OUTAGE_STATUS_CHECK: "outageStatusCheck",
    ADSL_SERVICES: "adslServices", //All ADSL services
    INCIDENT_NUMBER: "incident_number",
    SERVICE_NAME: "serviceName",
    SPEED_OUTAGE_RESULTS: "speedOutageResponse",
    OUTAGE_START: "outageStart",
    OUTAGE_ETA: "outageEta",
    OUTAGE_CAUSE: "outageCause",
    OUTAGE_SOURCE: "outageSource",
    DISASTER_DESCRIPTION: "disasterDescription",
    CONTACT_ID: "contactId",
    REGISTERED_PHONE_NUMBER: "registeredPhoneNumber",
    PROVIDED_CONTACT_NUMBER: "providedContactNumber",
    UTTERANCE: "utterance",
    FAULT_SERVICES: "faultServices",
    DATA_GATHER_SUCCESS: "dataGatherSuccess",
    MVP3DEEPLINK: "deeplinkmvp3",
    BROADBAND_INCIDENTS: "broadbandIncident",
    HOMEPHONE_INCIDENTS: "homephoneIncident",
    MOBILE_INCIDENTS: "mobileIncident",
    EMAIL_INCIDENTS: "emailIncident",
    CUSTOMER_HAS_HIGH_SPEED_TIER: "customerHasHighSpeedTier",
    IS_WORKFLOW_RESUMED: 'isWorkflowResumed',
    BASE_SNOW_RESPONSE: "baseSnowResponse",
    MOBILE_TYPE: "mobileType",
    STATE_NAME: "stateName",
    DIAGNOSTIC_INFO: "diagnosticInfo",
    PRECHECK_RESULT: "precheckResult",
    WIFI_HAZARD: "wifiHazard",
    BOOSTER_LIST: "boosterList",
    TOPOLOGY: "topology",
    IS_AUTO_SYMPTOM: "isAutoSymptom",
    SYMPTOM_RESPONSE: "symptomResponse",
    ACCOUNT_STATUS: "accountStatus",
    SCREEN_RESPONSE: "screenResponse",
    CALLBACK_DETAILS: "callbackDetails",
    DECRYPTED_TOKEN_SERVICE:"decryptedTokenService",
    USER_NAME: "userName",
    NATIONAL_MASS_OUTAGE:"nationalMassOutage"
};
