import React from "react";
import { AuthConsumer } from "../identity/caiman/AuthProvider";

const setDeeplinkStorage = () => {
    const search = window.location.search.substring(1);
    sessionStorage.setItem(
        "deeplink",
        ('{"' + decodeURI(search.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}'),
    );
}

const RedirectFaultTracker = ()=>{
    return (<AuthConsumer>
        {({ isAuthenticated, signinRedirect }) => {
            setDeeplinkStorage();
            if (isAuthenticated()) {
                window.location = "/smart-troubleshooting/deeplink/faulttracker";
            } else {
                sessionStorage.setItem("target_redirect_route", "/smart-troubleshooting/deeplink/faulttracker");
                signinRedirect();
            }
        }}
    </AuthConsumer>)
}

export default RedirectFaultTracker
