import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<circle cx="9.67" cy="19.6" r="1"/>
	<path d="m14.52 8.38 1.34-1.34 1.34 1.34.85-.85-1.34-1.34 1.34-1.34L17.2 4l-1.34 1.34L14.52 4l-.85.85 1.34 1.34-1.34 1.34.85.85zM17.97 12.08l-.85.84L20 15.8H9.95V17H20.2l-3.08 3.08.85.84 4.43-4.42-4.43-4.42z"/>
	<path d="M14.57 21.23h-9.8V3.77h6.49a5.3 5.3 0 0 0-.54 1.63H8.05v1.2h2.63a5.2 5.2 0 0 0 3.89 4.63V14h1.2v-2.59h.09a5.23 5.23 0 1 0 0-10.41 5.19 5.19 0 0 0-3.75 1.61H3.57v19.82h12.2V19h-1.2Zm1.29-19.06a4 4 0 1 1-4 4 4 4 0 0 1 4-4Z"/>
</svg>); 
 }; 
 export default SVG;