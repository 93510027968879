import React, {Component} from "react";
import {Route} from 'react-router-dom';
import appAnalytics from "./AppAnalytics";
import AppLog from "./AppLog";

const log = new AppLog("OmnitureTracker");

class OmnitureTracker extends Component {

    constructor(props) {
        super(props);
        log.info("Loaded", this.props);
        this.omnitureCall = this.omnitureCall.bind(this);
        this.getSubSection = this.getSubSection.bind(this);
        this.getAuthenticationStatus = this.getAuthenticationStatus.bind(this);
    }


    componentDidMount() {
        this.omnitureCall(this.props.location.pathname);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.location.pathname !== nextProps.location.pathname
            || this.props.location.search !== nextProps.location.search) {
            this.omnitureCall(nextProps.location.pathname);
        }
    }

    omnitureCall(path) {
        log.info("logged", path);
        let _path = [];
        let pathNameSections = path.split("/");

        let pagePath = pathNameSections[pathNameSections.length - 1];

        if (path === "/") {
            _path.push(document.title);
        }

        var subSection = this.getSubSection(path);

        var authenticationStatus = this.getAuthenticationStatus(path);
        const isStatus = this.getStatus(authenticationStatus);
        switch (subSection) {
            case null:
                //for non-updated page names
                break;
            case "ivr":
                _path.push(subSection, authenticationStatus, pagePath);
                break;
            case "email":
                //for email pages

                if (authenticationStatus === "hybrid") {
                    _path.push(pagePath, authenticationStatus);

                } else if (isStatus) {
                    _path.push(authenticationStatus, subSection);

                    //hard-coded for /outcome page
                    if (pagePath === "outcome") {
                        _path.push("csr-outcome");

                    } else if (pagePath === "troubleshoot-email-issue") {
                        //hard-coded for /troubleshoot-email-issue page
                        _path.push("issue");

                    } else if (subSection !== pagePath) {
                        var poppedSection = pathNameSections.pop();
                        var pageName = "";
                        while (poppedSection !== subSection) {
                            pageName = poppedSection + ":" + pageName;
                            poppedSection = pathNameSections.pop();
                        }
                        _path.push(pageName);
                    }
                }

                break;
            case "messagebank" :
                //for messagebank pages

                if (isStatus) {
                    _path.push(authenticationStatus, subSection);
                    if (subSection !== pagePath) {
                        var poppedMessageBankSection = pathNameSections.pop();
                        var pageMessageBankName = "";
                        while (poppedMessageBankSection !== subSection) {
                            pageMessageBankName = poppedMessageBankSection + ":" + pageMessageBankName;
                            poppedMessageBankSection = pathNameSections.pop();
                        }
                        _path.push(pageMessageBankName);
                    }
                }
                break;
            case "wifi" :
                //for wifi pages
                if (isStatus) {
                    _path.push(authenticationStatus, subSection);
                    if (subSection !== pagePath) {
                        var poppedWifiSection = pathNameSections.pop();
                        var pageWifiName = "";
                        while (poppedWifiSection !== subSection) {
                            pageWifiName = poppedWifiSection + ":" + pageWifiName;
                            poppedWifiSection = pathNameSections.pop();
                        }
                        _path.push(pageWifiName);
                    } else {
                        _path.push("index");
                    }
                }
                break;

            case "smartmodem" :
                //for smartmodem pages
                _path.push("unauthenticated", subSection);
                break;

            case "home-broadband:speed":
                if (pagePath === "diagnostic_intro") {
                    _path.push("authenticated", subSection, "diagnostic-intro");
                } else if (pagePath === "outage" || pagePath === "congestion" || pagePath === "shaping") {
                    _path.push("authenticated", subSection, "check-diagnostics");
                } else if (pagePath === "congestion-found") {
                    _path.push("authenticated", subSection, "congestion-found");
                } else if (pagePath === "outage-found-fixed") {
                    _path.push("authenticated", subSection, "outage-found");
                } else if (pagePath === "credit-shaping-found") {
                    _path.push("authenticated", subSection, "credit-found");
                } else if (pagePath === "shaping-found") {
                    _path.push("authenticated", subSection, "shaping-found");
                } else if (pagePath === "test") {
                    _path.push("authenticated", subSection, "speedtest");
                } else if (pagePath === "speed") {
                    _path.push("authenticated", subSection, "ookla-intro");
                } else if (pagePath === "result") {
                    _path.push("authenticated", subSection, "speedtest-results");
                }

                break;

            default:
                if (authenticationStatus === "authenticated" && _path.length === 0) {
                    _path.push("authenticated", subSection);

                } else if (authenticationStatus === "unauthenticated" && _path.length === 0) {
                    _path.push("unauthenticated", subSection);

                }
        }

        if (_path.length === 0)
            _path.push(pagePath);

        appAnalytics.send({
            eventType: "pageName",
            path: _path
        });
    }

    getStatus(authenticationStatus) {
        return authenticationStatus === "authenticated" || authenticationStatus === "unauthenticated";
    }

    getSubSection(pathName) {
        if (/\/ivr-gather/i.test(pathName)) {
            return "ivr";
        }
        if (/\/email/i.test(pathName)) {
            return "email";
        }
        if (/\/messagebank/i.test(pathName)) {
            return "messagebank";
        }
        if (/\/wifi/i.test(pathName)) {
            return "wifi";
        }
        if (/\/smartmodem/i.test(pathName)) {
            return "smartmodem";
        }
        if (/\/home-broadband\/speed/i.test(pathName) || /\/home-broadband\/bbspeed/i.test(pathName)) {
            return "home-broadband:speed";
        }
        if (/\/home-broadband/i.test(pathName)) {
            return "home-broadband";
        }
        if (/\/home-phone/i.test(pathName)) {
            return "home-phone";
        }
        if (/\/priority-assist-customer/i.test(pathName)) {
            return "priority-assist-customer";
        }
        if (/\/select-service/i.test(pathName)) {
            return "select-service";
        }
        if (/\/trouble-tickets/i.test(pathName)) {
            return "trouble-tickets";
        }
        if (/\/transfertoagent/i.test(pathName)) {
            return "transfer-to-agent";
        }
        if (/\/deeplink\/resumeProactiveFault/i.test(pathName)) {
            return "proactive-resume-flow";
        }
        if (/\/incorrectIncident/i.test(pathName)) {
            return "proactive-incorrect-incident";
        }
        if (/\/closedIncident/i.test(pathName)) {
            return "proactive-closed-incident";
        }
        if (/\/deeplink\/resumeIncident/i.test(pathName)) {
            return "resume-proactive-reactive-smartfix";
        }
        return null;
    }

    getAuthenticationStatus(pathName) {
        if (/\/u\//i.test(pathName)) {
            return "unauthenticated";
        }
        if (/\/h\//i.test(pathName)) {
            return "hybrid";
        }
        return "authenticated";
    }




    render() {
        return null;
    }
}

export default () => {
    return <Route component={OmnitureTracker}/>
}
