import React, {Component} from "react";
import { AuthConsumer } from "../identity/caiman/AuthProvider";
import RouteWrapper from "../common/RouteWrapper";
import DependencyResolver from "../identity/util/DependencyResolver";
import appAnalytics from "../common/util/AppAnalytics";
import { Switch} from "react-router-dom";
import AppLog from "../common/util/AppLog";
import BaseRunTimeContainer from "../common/BaseRunTimeContainer";
const log = new AppLog("Fault tracker Appointment");
const newrelic = window.newrelic || {setCustomAttribute:()=>{}};

class LoadingComponent extends BaseRunTimeContainer {
    constructor(props) {
        super(props, ["Spinner"]);
    }
    /* istanbul ignore next */
    render(){
        const {Spinner} = this.runTimeComponents;
        return(  <div style = {{position: "fixed", left: "45%"}}>
            <Spinner size="medium" />
        </div>);
    }
}

export default class FaultTrackerAppointment extends DependencyResolver {

    constructor(props) {
        super(props);
        this.faultTrackerComponents = {
            RouteFaulttracker: LoadingComponent
        };
        this.ROUTE_PATHS = {
            FAULT_TRACKER_APPT: `${this.props.match.url}`
        };
        this.state = {
            workflowDependenciesLoaded : false
        };
        this.onRouteChanged(this.props.location);

    }

    onRouteChanged(_location) {
        this.loadDependency(
            "faultTrackerComponents",
            "getRouteFaultTrackerDependency",
            "selfservice-faulttracker",
            "faulttracker",
            [
                this.ROUTE_PATHS.FAULT_TRACKER_APPT,
                `${this.ROUTE_PATHS.FAULT_TRACKER_APPT}:all`
            ],
            {
                webHostName: window.metadata.dependencies["selfservice-faulttracker"].url,
                bffHostName: window.metadata.dependencies["selfservice-faulttracker"].bffApiUrl,
                appAnalytics : appAnalytics,
                AppLog: AppLog
            },
            _location)
            .then(_methodName=>{
                /* istanbul ignore next */
                log.info("Dependency Loaded", _methodName);
            }).catch(e=>{
            if(e){
                /* istanbul ignore next */ log.warn("Dependency failed to Loaded", e);
            }
        });
    }

    render(){
        return  <AuthConsumer>
            {({ isAuthenticated, signinRedirect }) => {
                sessionStorage.setItem("faulttracker",JSON.stringify(window.location.pathname));
                if (isAuthenticated()) {
                    if(newrelic){
                        newrelic.setCustomAttribute("Faulttracker-appointment", "authenticated")
                    }
                    return <Switch>
                        <RouteWrapper path={this.ROUTE_PATHS.FAULT_TRACKER_APPT}  component={this.faultTrackerComponents.RouteFaulttracker} {...this.props}/>
                    </Switch>
                } else {
                    if(newrelic){
                        newrelic.setCustomAttribute("Faulttracker-appointment", "signinRedirect")
                    }
                    signinRedirect();
                }
            }}
        </AuthConsumer>
    }
}
