const CONSTANTS = {
    SERVICE_NAME: {
        ADSL: "ADSL",
        NBN: "NBN Fibre Access",
        CABLE: "Cable Internet Access Type",
        LANDLINE: "Voice Service",
        TELEPHONE_LINE: "Telephone Line Service",
        INTERNET: "Internet Service",
        EMAIL: "Applications & Entertainment Services",
        MOBILE: "Mobile Service",
        PREPAID_MOBILE: "Pre-Paid Mobile Service",
        DAVINCI_MOBILE: "Mobile SIM"

    },
    DEEPLINK_MAPPING:{
        WIFI:"WIFI_ISSUE",
        MOBILE_SPEED:"MOBILE_SPEED_IS_SLOW",
        BROADBAND_SPEED:"INTERNET_SLOW",
        MOBILEPREPAID_SPEED:"MOBILE_SPEED_IS_SLOW",
        BROADBAND_CANNOTCONNECT:"INTERNET_DOWN",
        INTERNET_DOWN: "INTERNET_DOWN",
        DROPPING_OUT:"DROPPING_OUT",
        INTERNET_SLOW:"INTERNET_SLOW",
        WIFI_ISSUE:"WIFI_ISSUE",
        GAME_BOOST:"GAME_BOOST",
        CANNOT_MAKE_CALLS:"CANNOT_MAKE_CALLS",
        CANNOT_RECEIVE_CALLS:"CANNOT_RECEIVE_CALLS",
        NO_CALLS:"NO_CALLS",
        MESSAGE_BANK:"MESSAGE_BANK",
        OTHER:"OTHER_PHONE_PROBLEM"
    },
    DEEPLINK_WITH_SERVICETYPE:{
        BROADBAND:"Broadband",
        VOICE:"Home phone",
        EMAIL:"Email",
        MOBILE:"Mobile",
        MOBILE_PREPAID:"Mobile - Pre-Paid",
        MOBILE_POSTPAID:"Mobile"
    },
    NON_ASSISTED_SERVICES_ACCESS_TYPES : ["UNITI", "Opticomm Fibre to the premises"],
    NON_ASSISTED_SERVICES_SUB_ACCESS_TYPES : ["UNITI_FTTP"],
    NON_ASSISTED_CUSTOMER_CARE_NUMBERS : {
        UNITI : "132200",
        OPTICOMM : "132200"
    },
    NON_ASSISTED_SERVICES_CONTENT :{
        UNITI : {
            h1:"A consultant will need to guide you through further steps online",
            p:"Please contact our support team using the method below."
        }
    }

};

export default CONSTANTS
