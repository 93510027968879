import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="#414141">
		<path d="M5.4 21.6h13.2V9.4H5.4v12.2Zm1.2-5.5h6.8v4.3H6.6v-4.3Zm8 4.3v-4.3h2.8v4.3h-2.8Zm2.8-5.5h-6.8v-4.3h6.8v4.3Zm-8-4.3v4.3H6.6v-4.3h2.8Z"/>
		<path d="M13.71.4H3.4v23.2h17.4V7.27L13.71.4Zm5.89 22h-15V1.6h8.62l6.38 6.18V22.4Z"/>
	</g>
</svg>); 
 }; 
 export default SVG;