import React from "react";
import { AuthConsumer } from "./AuthProvider";
import Loading from "../../common/Loading";

const errorQueryParam = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let code = params.get('error');
    /*console.log('code ---> ',code);*/
    return code;
}
const newrelic = window.newrelic || {
    setCustomAttribute: () => {
    }
};

export const Callback = () => (
    <AuthConsumer>
        {
            ({ signinRedirectCallback, signinSilentCallback }) => {
                if (sessionStorage.hasOwnProperty("challenge2SV-c2c") && sessionStorage.getItem("challenge2SV-c2c") === "true") {
                    let error = errorQueryParam();
                    if (error && error.startsWith('access_denied')) {
                        // clearStaleState();
                        newrelic.setCustomAttribute("2-SV-not-completed", "access_denied");
                        window.location = '/error';
                        return (
                            <Loading/>
                        )
                    } else {
                        signinRedirectCallback();
                        return (
                            <Loading/>
                        )
                    }
                } else {
                    if (sessionStorage.hasOwnProperty("SILENT_SIGN_IN") && sessionStorage.getItem("SILENT_SIGN_IN") === "true") {
                        signinSilentCallback();
                        return (
                            <Loading/>
                        )
                    } else {
                        signinRedirectCallback();
                        return (
                            <Loading/>
                        )
                    }
                }
            }

        }
    </AuthConsumer>
);
