import React from "react";
import BaseRunTimeContainer from "./BaseRunTimeContainer";
import AppLog from "./util/AppLog";
import TransferToAgent from "../images/import/TransferToAgent";
import "./TransferToAgentMessage.css";
import {messageUs} from "./util/GenericMessageContent";
import cache, {APP_CACHE} from "../common/util/cacheManager";
import appAnalytics from "./util/AppAnalytics";
import CallMeNowButton from "./CallbackUser/CallMeNowButton";
import {getCallbackDetails, whetherItsNBNService} from "./util/ClickToCallUtils";

const log = new AppLog("TransferToAgentMessage");

export default class TransferToAgentMessage extends BaseRunTimeContainer {

    constructor(props) {
        super(props, ["Section", "Button", "ShowHide", "Loader"]);
        this.state.selectedService = cache.getProperty(APP_CACHE.SELECTED_SERVICE);
        this.state.nbnServiceIds = cache.getProperty(APP_CACHE.NBN_SERVICES);
        this.state.isLoading = false;
        log.info("Loaded", props);
        appAnalytics.send({
            eventType: "pageName",
            path: ["transfer-to-agent"]
        });
    }

    async componentDidMount() {
        const {serviceId} = this.state.selectedService;
        if (this.state.selectedService && serviceId && whetherItsNBNService(serviceId, this.state.nbnServiceIds)) {
            this.setState({isLoading: true})
            const callbackAvailability = await getCallbackDetails(serviceId, this.state.selectedService);
            log.info("Get Callback Details Status :: ", callbackAvailability);
            this.setState({isCallbackAvailable: callbackAvailability, isLoading: false});
        }
    }

    /* istanbul ignore next*/
    Message() {
        window.lpTag.newPage(document.URL, {section: ["telstra", "consumer", "troubleshoot", "help"]});

        window.livePerson.show({
            isDirect: true,
            section: ["telstra", "consumer", "troubleshoot", "help", "fault", "dtq"]
        });
    }

    renderCallbackBodyContent() {
        return this.state.isCallbackAvailable ? <p className="rt-body-text-big-body-short rt-margin-top-spacing3x">
                Please contact our support team using messaging or request call back feature below.</p> :
            <p className="rt-body-text-big-body-short rt-margin-top-spacing3x">
                Please contact our support team using the messaging feature below.</p>;
    }

    render() {
        const {Section, Button, ShowHide, Loader} = this.runTimeComponents;
        const button = <h2 className={"rt-heading-c rt-margin-bottom-spacing3x"}>How to use My Telstra app
            messaging</h2>;
        const content = <div
            className={"rt-body-text-big-body-short rt-margin-top-spacing3x rt-margin-bottom-spacing3x"}>
            <ol>
                <p>
                    <li>Open the My Telstra app</li>
                </p>
                <p>
                    <li>Navigate to the Get Help tab</li>
                </p>
                <p>
                    <li>Tap on blue speech bubble icon at the lower right of the screen</li>
                </p>
                <p>
                    <li>When you will start your messaging conversation please type "Fault"</li>
                </p>
                <p>
                    <li>If directed to use Smart Troubleshooting to resolve your issue please tap "More Help"</li>
                </p>
                <p>
                    <li>You'll be provided information about connecting with a member of the online team
                        who will be able to see the steps you have already taken
                    </li>
                </p>
            </ol>
        </div>
        return (this.state.isLoading ? <Loader/> :
                <React.Fragment>
                    <Section>
                        <TransferToAgent/>
                        <h1 className="rt-heading-c rt-margin-top-spacing3x">

                            {this.props?.location?.state?.fromNbnTest ?
                                "We haven't found the problem yet. Let's talk" :
                                "A consultant will need to guide you through further steps online"
                            }
                        </h1>

                        {this.renderCallbackBodyContent()}

                        <p className="rt-body-text-big-body-short">
                            {this.props?.location?.state?.fromNbnTest ? "We'll be able to see the steps you've already taken and help you get back online" :
                                "You will be asked to verify your identity and once confirmed they will be able to see the steps you have already taken."
                            }
                        </p>

                        <Button className="rt-margin-top-spacing4x rt-margin-bottom-spacing5x"
                                preventOmniture={true}
                                variant="HighEmphasis" onClick={() => messageUs()}>Message us</Button>

                        <CallMeNowButton isCallbackAvailable={this.state.isCallbackAvailable} />

                        <p className="rt-body-text-big-body-short rt-margin-bottom-spacing3x">
                            If you are having trouble using the feature above due to ad blockers or other browser
                            restrictions,
                            you can also message us via the My Telstra app.
                        </p>

                        <ShowHide className={"rt-margin-top-spacing3x rt-margin-bottom-spacing3x"} button={button}
                                  content={content} open={false}/>
                        <div className={"rt-horizontal-divider"}/>

                        <script type="text/javascript">
                            window.usabilla_live('virtualPageView');
                        </script>

                    </Section>
                </React.Fragment>
        )
    }
}
