import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="#414141" fillRule="evenodd">
		<path d="M2.4 15.06h1.2A8.36 8.36 0 0 1 3.87 13l-1.16-.3a9.37 9.37 0 0 0-.31 2.36zm1.06-4.4 1.07.55a8.45 8.45 0 0 1 1.81-2.35L5.53 8a9.68 9.68 0 0 0-2.07 2.66zm12.42-4.38a9.68 9.68 0 0 0-3.3-.8l-.07 1.2a8.17 8.17 0 0 1 2.88.7l.49-1.1zm1.84 1.07-.72 1a8.35 8.35 0 0 1 1.47 1.44L11 14.22v3.26h3L18.66 10c.12.17.27.31.38.49l1-.66a9.57 9.57 0 0 0-2.32-2.48zM7.25 6.72l.6 1a8.22 8.22 0 0 1 2.8-1l-.2-1.18a9.42 9.42 0 0 0-3.2 1.18zm13.15 8.34h1.2a9.49 9.49 0 0 0-.6-3.34l-1.12.42a8.52 8.52 0 0 1 .52 2.92z"/>
		<path d="M12 3.46C5.596 3.466.406 8.656.4 15.06v5.54h23.2v-5.54C23.594 8.656 18.404 3.466 12 3.46ZM22.4 19.4H1.6v-4.34c0-5.744 4.656-10.4 10.4-10.4 5.744 0 10.4 4.656 10.4 10.4v4.34Z" fillRule="nonzero"/>
	</g>
</svg>); 
 }; 
 export default SVG;