const AllowServiceList = {
    INTERNET_TITLE : 'Broadband',
    PHONE_TITLE : 'Home phone',
    EMAIL_TITLE : "Email",
    MOBILE_TITLE : "Mobile",
    PREPAID_TITLE : "Mobile - Pre-Paid",
    HOME_PHONE_TITLE : "Home Phone"
}

export default AllowServiceList;
