import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<defs>
		<clipPath id="id-6a">
			<path d="m17.59 4.09-1.34 1.34-1.34-1.34-.85.85 1.34 1.34-1.34 1.34.85.85 1.34-1.34 1.34 1.34.85-.85-1.34-1.34 1.34-1.34-.85-.85z"/>
		</clipPath>
	</defs>
	<path d="m10.99 14.68 2.88-2.88-.85-.85-4.43 4.43 4.43 4.42.85-.85-3.08-3.07h10.25v-1.2H10.99z"/>
	<path d="M16.81 17.74h-3.02l1.41 1.2h2.81V17h-1.2v.74zM3.16 20.59V8.19h8.25a5.23 5.23 0 0 0 4.84 3.29 5.51 5.51 0 0 0 .57-.06v2.08H18v-2.35a5.22 5.22 0 1 0-7-4.9 4.53 4.53 0 0 0 .1.75H2v15.91l5.61-4L11 19l-1.3-1.26H7.18ZM16.25 2.23a4 4 0 1 1-4 4 4 4 0 0 1 4-4Z"/>
	<path d="m17.59 4.09-1.34 1.34-1.34-1.34-.85.85 1.34 1.34-1.34 1.34.85.85 1.34-1.34 1.34 1.34.85-.85-1.34-1.34 1.34-1.34-.85-.85z" fillRule="evenodd"/>
	<g clipPath="url(#id-6a)">
		<path d="M9-1h14v14H9z"/>
	</g>
</svg>); 
 }; 
 export default SVG;