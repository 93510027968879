import React from "react";
import imgLoading from "./images/app-loading.svg";

const Loading = () => {
    const basePath = "/smart-troubleshooting";
    let title = "Loading...";
    /* istanbul ignore next*/
    if((window.location.pathname.indexOf(`${basePath}/u/`)>-1) ||
        (window.location.pathname.indexOf(`${basePath}/redesign/u/`)>-1) ||
        (window.location.pathname.indexOf(`${basePath}/admin`)>-1) ||
        (window.location.pathname.indexOf(`${basePath}/i/`)>-1) ){
        title = "Loading services...";
    }
    return (
        <section>
            <h1 className={"margin-clean margin-bottom-l"}>Welcome to Smart Troubleshooting</h1>
            <img src={imgLoading}
                 className={"margin-clean margin-bottom-xxs"}
                 style={{"maxWidth":"4rem", "height":"4rem"}}
                 alt={""}/>
            <p className={"margin-clean margin-bottom-xxs"}>{title}</p>
        </section>
    );
};

export default Loading