import React from "react";
import imgLoading from "./images/app-loading.svg";
import { AuthConsumer } from "../identity/caiman/AuthProvider";

const Loader = () => {
    let title = "Loading...";
    return (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect }) => {
                console.log("Redirecting to");
                if (isAuthenticated()) {
                   // do nothing
                } else {
                    signinRedirect();
                    return;
                }
               return(<section>
                        <h1 className={"margin-clean margin-bottom-l"}>Redirecting to Smart Troubleshooting</h1>
                        <img src={imgLoading}
                             className={"margin-clean margin-bottom-xxs"}
                             style={{"maxWidth":"4rem", "height":"4rem"}}
                             alt={""}/>
                        <p className={"margin-clean margin-bottom-xxs"}>{title}</p>
                   </section>
                    );
            }}
        </AuthConsumer>
    )
};

export default Loader