import {Component} from "react";

export default class DependencyResolver extends Component {

    constructor(props) {
        super(props);
        this.loadDependency = this.loadDependency.bind(this);
    }

    /* istanbul ignore next */
    _importDependency(url, id){
        setTimeout(function(){
            var shouldImport = true;
            var scripts = document.getElementsByTagName("script");
            for(var i=0;i<scripts.length; i++){
                if(scripts[i].src=== url+"/import.js?id="+id)shouldImport = false;
            }
            if(shouldImport) {
                var script = document.createElement("script");
                script.src = url + "/import.js" + ((id && id != "undefined") ? "?id=" + id : "");
                document.head.appendChild(script);
            }
        },0);
    }

    /* istanbul ignore next */
    _getDependency(dependencyName, dependentPaths){
        var promise = new Promise((resolve, reject) => {
            var _LoadTimer = setInterval(() => {
                if (window[dependencyName]){
                    clearInterval(_LoadTimer);
                    resolve({exec: window[dependencyName], paths: dependentPaths || []});
                }
            }, 100);
        });

        return promise;
    }

    _checkRoutes(routes, pathName){
        var isValid = false;
        routes.forEach(_route=>{
            if(_route === pathName || pathName.match(new RegExp(_route.replace(/:[a-z].*/g, '.*')+"$"))){
                isValid = true;
            }
        });
        return isValid;
    }

    loadDependency(componentName, loadMethodName, metaDataDependency, id, routes, config, location){
        return new Promise((resolve, reject)=>{
            if(this._checkRoutes(routes, location.pathname) && this.state.workflowDependenciesLoaded != loadMethodName) {
                this._getDependency(loadMethodName, routes)
                    .then((_d) => {
                        this[componentName] = _d.exec(config);
                        this.setState({
                            workflowDependenciesLoaded: loadMethodName
                        }, () => {
                            resolve(loadMethodName);
                        });
                    }).catch(reject);
                this._importDependency(window.metadata.dependencies[metaDataDependency].url, id);
            }else{
                reject();
            }
        });
    }
}
