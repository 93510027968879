import React from 'react';
import BannerCI from './Banner_ci.config';
import BannerNonProd from './Banner_nonprod.config';
import BannerProd from './Banner_prod.config';
import BannerLocal from './Banner_local.config';


export default class Config{
	constructor(props){
		this.props=props;
	}
	getConfig(){
		if(this.props.environment==="prod")
			return new BannerProd();
		else if(this.props.environment==="ci")
			return new BannerCI();
		else if(this.props.environment==="nonprod")
			return new BannerNonProd();
		else if(this.props.environment==="local")
			return new BannerLocal();

	}
}
