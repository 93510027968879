import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="none" fillRule="evenodd" stroke="#414141" strokeWidth="1.2" transform="translate(1 9)">
		<path d="M4.26 8.258h13.773" strokeLinecap="square"/>
		<path d="M18.033 8.25a3.975 3.975 0 0 0 2.82-1.175 4.159 4.159 0 0 0 1.214-2.95C22.067 1.847 20.26 0 18.033 0 15.806 0 14 1.847 14 4.125a4.16 4.16 0 0 0 1.14 2.874"/>
		<ellipse cx="4.033" cy="4.125" rx="4.033" ry="4.125"/>
	</g>
</svg>); 
 }; 
 export default SVG;