import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="18" width="20" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="none" fillRule="evenodd">
		<path d="M-2-3h24v24H-2z"/>
		<path d="M15.25 5.25a4.49 4.49 0 0 1 4.5 4.5v3a4.49 4.49 0 0 1-4.5 4.5H4.75a4.49 4.49 0 0 1-4.5-4.5v-3a4.49 4.49 0 0 1 4.5-4.5Zm0 1.5H4.75a3 3 0 0 0-3 3v3a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-3a3 3 0 0 0-3-3ZM5.5 9a2.25 2.25 0 1 1 0 4.5 2.25 2.25 0 0 1 0-4.5Zm9 3a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Zm-8.573-1.802a1.13 1.13 0 1 0-.874 2.084 1.13 1.13 0 0 0 .874-2.084ZM13 10.5a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Zm3 0a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5ZM14.5 9a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5ZM10 0a.75.75 0 0 1 .75.75v3a.76.76 0 0 1-.75.75.76.76 0 0 1-.75-.75v-3A.75.75 0 0 1 10 0Z" fill="#282828"/>
	</g>
</svg>); 
 }; 
 export default SVG;