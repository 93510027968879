import React, {Component} from "react";
import runTimeComponent from './RunTimeComponents';

export default class BaseRunTimeContainer extends Component{

    constructor(props, runTimeComponentList) {
        super(props);
        const _tempComponents = {};
        /* istanbul ignore next*/
        if(runTimeComponentList && runTimeComponentList.length>0){
            runTimeComponentList.forEach(_componentName=>{
                _tempComponents[_componentName] = props[_componentName] || runTimeComponent.defaultComponent;
            });
        }
        /* istanbul ignore next*/
        this.state={
            runTimeComponents: _tempComponents
        }
        this.runTimeComponents = this.state.runTimeComponents;
        /* istanbul ignore next*/
        runTimeComponent.getComponents().then(components=>{
            this.runTimeComponents = components;
            this.setState({
                runTimeComponents: components
            });
        })
    }
}