import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="240" width="311" viewBox="0 0 311 240" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="none" fillRule="evenodd">
		<path d="M-124-160H900v1426H-124z" fill="#FFF"/>
		<path d="M183.882 73.047c-.063-5.106-.665-9.203-3.12-13.403-.016-.141-.109-1.039-.049-1.168 1.053-2.277 1.627-5.547 1.315-8.416-.103-.938-.703-1.893-1.178-2.718-.01-.017-.856-1.948-.89-1.711l-.365-.409c-5.262 3.379-14.447.809-20.223.044-5.427-.72-11.027-1.232-15.782 1.781-8.088 5.125-9.778 14.661-10.583 23.654-.009.05-.023 1.219-.032 1.27-.036.191.666 3.607.678 3.779 1.303 3.18 2.026-.589 2.181-2.979l.007-.101s5.304-22.215 22.52-22.62c17.216-.405 21.226 20.264 21.226 20.264.125.743 1.274 3.714 1.307 4.163.32 4.283 2.833 2.382 2.988 0 .031-.486.023-.96 0-1.43" fill="#B2E0FD"/>
		<path d="M181.74 77.76s2.052.166 2.052-6.981c0-7.27-3.665-11.451-3.665-11.451 7.174-11.541-6.787-19.68-6.787-19.68-3.87 3.345-10.48 3.26-14.892 3.26-18.091 0-25.472 14.246-25.472 27.87 0 7.147 2.052 6.981 2.052 6.981" stroke="#5E50BF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M199.7 161.375c-22.129 0-10.637 60.517-39.979 66.385 14.671 2.69 38.511-2.078 39.367-13.57 0-.367.122-3.3.122-3.669-.61-12.225.49-49.146.49-49.146" fill="#B2E0FD"/>
		<path d="M119.01 163.453v52.203" stroke="#B2E0FD" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M120.844 183.87h-15.16l.245-21.762s-.245-17.36 13.326-26.163c5.379-3.423 11.125-6.112 16.382-8.679 8.313-4.158 13.57-8.68 13.57-19.44M199.21 183.87h15.282l-.244-21.762s.244-17.36-13.327-26.163c-5.379-3.423-11.125-6.112-16.382-8.679-8.313-4.158-13.57-8.68-13.57-19.44" stroke="#5E50BF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="m111.186 209.177-.246-4.278-.977-21.03M120.844 217.246v-55.138M199.088 162.108v54.893" stroke="#5E50BF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M132.557 77.928c3.307-1.37 7.474 1.11 9.31 5.54 1.833 4.43.64 9.13-2.666 10.5-3.306 1.369-7.474-1.111-9.309-5.54-1.834-4.43-.64-9.13 2.665-10.5" fill="#FFF"/>
		<path d="M132.557 77.928c3.307-1.37 7.474 1.11 9.31 5.54 1.833 4.43.64 9.13-2.666 10.5-3.306 1.369-7.474-1.111-9.309-5.54-1.834-4.43-.64-9.13 2.665-10.5Z" stroke="#5E50BF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M177.791 83.448c1.834-4.429 6.002-6.909 9.309-5.54 3.306 1.37 4.499 6.07 2.664 10.5-1.835 4.43-6.002 6.91-9.308 5.54-3.307-1.37-4.499-6.07-2.665-10.5" fill="#FFF"/>
		<path d="M177.791 83.448c1.834-4.429 6.002-6.909 9.309-5.54 3.306 1.37 4.499 6.07 2.664 10.5-1.835 4.43-6.002 6.91-9.308 5.54-3.307-1.37-4.499-6.07-2.665-10.5Z" stroke="#5E50BF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M159.844 50.152c13.234 0 23.962 14.361 23.962 32.077 0 17.715-10.728 32.076-23.962 32.076s-23.962-14.36-23.962-32.076 10.728-32.077 23.962-32.077" fill="#FFF"/>
		<path d="M150.919 81.541a2.201 2.201 0 1 1 0 4.402 2.201 2.201 0 0 1 0-4.402M168.402 81.541a2.2 2.2 0 1 1 0 4.4 2.2 2.2 0 0 1 0-4.4" fill="#5E50BF"/>
		<path d="M159.844 83.742c0 4.89 3.179 5.135 3.179 7.946 0 2.69-3.301 2.935-3.301 2.935" fill="#FFF"/>
		<path d="M159.844 83.742c0 4.89 3.179 5.135 3.179 7.946 0 2.69-3.301 2.935-3.301 2.935M146.762 78.852c2.445-2.2 6.357-2.2 8.68 0M164.245 78.852c2.445-2.2 6.357-2.2 8.68 0M157.643 102.692c2.567 1.467 5.38.122 6.48-.978M199.21 172.989h14.915" stroke="#5E50BF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="m147.007 118.585-10.881 23.962s8.68-2.078 13.082-2.2c4.645-.122 9.29 2.078 10.88 6.479l-13.081-28.241Z" fill="#FFF"/>
		<path d="m173.17 118.585 10.88 23.962s-8.68-2.078-13.08-2.2c-4.647-.122-9.293 2.078-10.882 6.479l13.081-28.241Z" fill="#FFF"/>
		<path d="m173.17 118.585 10.88 23.962s-8.68-2.078-13.08-2.2c-4.647-.122-9.293 2.078-10.882 6.479l13.081-28.241ZM160.088 146.948v21.762" stroke="#5E50BF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M5 228.492h301.088V32H5z"/>
		<path d="M173.169 175.189h13.082v-13.081h-13.082z" stroke="#5E50BF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M173.17 162.108v-4.279h13.081v4.28" stroke="#5E50BF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M159.844 50.549c13.234 0 23.962 14.272 23.962 31.879 0 17.605-10.728 31.878-23.962 31.878s-23.962-14.273-23.962-31.878c0-17.607 10.728-31.88 23.962-31.88Z" stroke="#5E50BF" strokeWidth="2"/>
		<path d="m147.007 118.585-10.881 23.962s8.68-2.078 13.082-2.2c4.645-.122 9.29 2.078 10.88 6.479l-13.081-28.241ZM105.929 172.989h14.915M208.991 208.689l1.101-24.818M127.713 73.086c0-22.139 13.983-40.086 31.233-40.086s31.233 17.947 31.233 40.086" stroke="#5E50BF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M135.318 93.129V68.646h-4.896s-7.345 0-7.345 7.344v9.793s0 7.346 7.345 7.346h4.896Z" fill="#B2E0FD"/>
		<path d="M135.318 93.129V68.646h-4.896s-7.345 0-7.345 7.344v9.793s0 7.346 7.345 7.346h4.896Z" stroke="#5E50BF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M184.285 68.646v24.483h4.896s7.345 0 7.345-7.345V75.99s0-7.345-7.345-7.345h-4.896Z" fill="#B2E0FD"/>
		<path d="M184.285 68.646v24.483h4.896s7.345 0 7.345-7.345V75.99s0-7.345-7.345-7.345h-4.896ZM189.182 93.129s-1.062 11.907-12.777 9.447M172.278 106.758H169.9a3.13 3.13 0 0 1-3.129-3.13v-.62a3.13 3.13 0 0 1 3.13-3.128h2.377a3.13 3.13 0 0 1 3.13 3.129v.619a3.13 3.13 0 0 1-3.13 3.13Z" stroke="#5E50BF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M60.663 200.703c0 .908-5.742 1.644-12.825 1.644-7.083 0-12.825-.736-12.825-1.644 0-.908 5.742-1.644 12.825-1.644 7.083 0 12.825.736 12.825 1.644" fill="#ECEDEE"/>
		<path d="M241.052 178.637v9.858" stroke="#E7E8E9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M234.798 163.048s11.317 6.238 16.25 6.384c-11.317 0-16.395-.29-16.395-.29l.145-6.094ZM236.684 171.36c1.097-1.245 27.822 1.67 28.728 6.383-11.317 0-28.873-.29-28.873-.29l.145-6.094ZM231.46 178.759s6.095 8.56 25.392 10.156c-11.317 0-24.901-.525-24.901-.525l-.49-9.631Z" fill="#F3F4F5"/>
		<path d="M284.412 190.167c0 1.071-14.144 1.94-31.591 1.94-17.447 0-31.591-.869-31.591-1.94 0-1.07 14.144-1.938 31.59-1.938 17.448 0 31.592.868 31.592 1.938" fill="#ECEDEE"/>
		<path d="M5 228.492h301.088V32H5z"/>
		<path d="M236.036 178.047h50.487v-8.406h-50.487zM272.802 178.637v9.858M245.671 183.567h21.705M231.56 188.733h50.488v-10.334H231.56zM282.532 173.844h-18.467M234.234 169.572h50.488v-9.031h-50.488z" stroke="#E7E8E9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M276.026 165.08c0 1.04-2.024 1.885-4.52 1.885-2.498 0-4.522-.845-4.522-1.886 0-1.042 2.024-1.887 4.521-1.887s4.521.845 4.521 1.887ZM243.888 161.018v8.601M240.285 176.531v8.601" stroke="#E7E8E9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="m228.765 66.787 10.272-10.27 9.853 9.851" stroke="#F3F4F5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M219.868 94.16h38.129V67.45h-38.129z" stroke="#E7E8E9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M219.855 84.943h18.558s8.001.852 8.001 9.023c3.065 0 4.257-13.279 11.067-9.534M232.347 76.097h15.168s4.374.465 4.374 4.933c1.675 0 2.327-7.26 6.05-5.212M247.959 76.104h6.981M239.319 84.999h13.032" stroke="#E7E8E9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M241.77 89.774h-7.353" stroke="#F3F4F5" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="m6 184.875 91.366.184M287.07 184.259l18.018.036M28.352 144.098s2.52 18.539 17.366 26.87c.377-8.567-6.907-21.108-17.367-26.87Z" stroke="#E7E8E9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M43.16 135.189s-6.356 15.219 4.579 26.437c1.25-5.764 2.462-14.42-4.578-26.437ZM61.664 159.748s-6.792-1.52-12.178 10.773c6.908-.117 6.613-5.687 12.178-10.773Z" stroke="#E7E8E9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M47.393 179.547s1.346-9.28.499-17.813" stroke="#E7E8E9" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M36.756 157.263s5.818 10.368 8.832 13.207" fill="#F3F4F5"/>
		<path d="M36.756 157.263s5.818 10.368 8.832 13.207" stroke="#E7E8E9" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M45.48 147.392s.235 9.134 2.226 13.817" fill="#F3F4F5"/>
		<path d="M45.48 147.392s.235 9.134 2.226 13.817M45.546 170.657s1.303 3.191 1.303 9.184M49.502 179.906c0-.326-1.35-4.43-.017-9.385" stroke="#E7E8E9" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M44.039 199.92s-5.31-3.055 1.739-20.066h4.718c7.049 17.011 1.739 20.066 1.739 20.066h-8.196Z" fill="#FFF"/>
		<path d="M44.039 199.92s-5.31-3.055 1.739-20.066h4.718c7.049 17.011 1.739 20.066 1.739 20.066h-8.196Z" stroke="#E7E8E9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
		<path d="M44.312 185.343s1.104 1.199 1.44 3.79c.339 2.619 3.839 10.176 6.479 10.176h-7.993s-3.717-2.784.074-13.966" fill="#F3F4F5"/>
	</g>
</svg>); 
 }; 
 export default SVG;