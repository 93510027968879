import React from "react";
import cache, {APP_CACHE} from "../common/util/cacheManager";
import AppLog from "../common/util/AppLog";
import WorkflowClient, {fetchWorkflowAsync, getLatestStep} from "../deeplink/WorkflowClient";
import {getServiceType} from "../service/util/serviceUtil";
import AllSymptomList from "./config/AllSymptomList";
import appAnalytics from "../common/util/AppAnalytics";

const log = new AppLog("SelectDeepLinkSymptom");

class SelectDeepLinkSymptom {
    constructor(){
        log.info("Create instance of SelectDeepLinkSymptom");
        appAnalytics.send({
            eventType: "pageName",
            path: ["select-deepLink-symptom"]
        });
    }
     async fetchDataFromDeepLink(deepLink){
        let deepLinkData= await WorkflowClient.fetchDeepLinkData(deepLink.signature, deepLink.token).then( (data) => {
            return data;
        }).catch( (error) => {
            log.warn("DeepLink Service is down", error);
        });
        return  deepLinkData;
    }
     selectServiceData(serviceData,allServices){
        let selectService;

        for (let service of allServices){
            if(serviceData.data.serviceId === service.serviceId){
                if(service.technologyType === "NBN_HFC")
                    service.technologyType="HFC";
                selectService=service;
                cache.saveProperty(APP_CACHE.SELECTED_SERVICE, service);
                break;
            }
        }
        return selectService;
    }


  async  selectSymptomData(selectService,deepLinkData,currentInstance){
    // let deepLinkData={"data":{"diagnosticId":"5d0f3b6c-82c9-479d-ba2f-861baa6210ad","source":"MyTelstraIPhone","serviceId":"auto_dierrcph@bigpond.com"},"signature":"w4MYKr9DALa5ZoVX%2B6QDCWOGHyIu4gcinSlu4x74JGR7qPVDm1BURGmUiAzsnNBc%2FAkmxwYG5goXT%2Br6Tmq3mF%2FItJBpeGrK39vZdRWpkuEZmzEynEfd%2F3lAKRvvTW3gV%2Fp12wG%2FIw7lXevJFaGmwZVFLxPxKi21UFZYVdSNiWgT4ptZoPTytbQx6pm4CXqvzKymzyrs%2BWhd%2Fx1OJvTPpY07v4O9CAq73DyPB7GbATULNVxa4a5oy5vouIrbEoD8LKlriYGGVjTq3HhwufyfFPGh8pb7%2FNikToQTEifR9AG4wB%2B4VZXuRXFuA%2FONjaOJNdU%2BmGFZ%2BgTiukhh%2FaVF5A%3D%3D"};
    // let selectService={"productName":"Internet Service","serviceId":"auto_dierrcph@bigpond.com","technologyType":"FTTP","subTechnologyType":"","displayOrder":10,"priorityAssist":false,"status":"Active","cac":"2022038042","referenceNumber":"YM73-WCWV","serviceViewId":"0547938348726d6671f76956e890eed0f84f2e2a"};

       //call to workflow api and get most likely symptom
     let selectedIssue = {
          "name": "",
          "label": "",
          "workflowName": "NBN Controller Workflow 2.0",
          "diagnosticTestName": "precheck",
          "snowSymptomName": ""
      };
      cache.saveProperty("event-run-diagnostic-initiated", "true");
      const diagnostic = {
          "outageRes": "Pass",
          "lstSerialNumber": null,
          "nemSerialNumber": null,
          "diagnosticRes": "Fail"
      };
      cache.saveProperty("diagnosticResults", diagnostic);



      cache.saveProperty("mobileOutageResponse", {
          "serviceStatus": [],
          "validAddress": true,
          "translated": false,
          "adborId": "",
          "massStateOutage": {"title": "", "description": ""},
          "future": null
      });
      cache.saveProperty("speedOutageResponse", {
          "serviceStatus": [],
          "validAddress": true,
          "translated": false,
          "adborId": "",
          "massStateOutage": {"title": "", "description": ""},
          "future": null,
          "title": "no outage",
          "description": "outage not present",
          "status": "NOOUTAGE"
      });

        ////  //Select Service
        let selectSymptom=    await    WorkflowClient.fetchWorkflow({
                    "type": "initiate_workflow",
                    "workflow_name": "NBN Controller Workflow 2.0",
                    "wf_inputs": {
                        "u_customer_id": selectService.cac
                    },
                    "source": "MyTelstra",
                    "all_steps": false
                }).then(async initiateWorkflowRes=>{
                    if (initiateWorkflowRes.correlation_id) {
                        var payload = {
                            "type": "get_latest_step",
                            "correlation_id": initiateWorkflowRes.correlation_id,
                            "all_steps": false
                        };
                        cache.saveProperty("correlationId",initiateWorkflowRes.correlation_id);
                        /* istanbul ignore next*/
                        if (initiateWorkflowRes.steps) {
                            payload.step = {
                                "id": initiateWorkflowRes.steps.id,
                                "activity": initiateWorkflowRes.steps.activity
                            };
                        }
                        //using call back
                        getLatestStep(payload, (data)=>{
                            this.updateStep(data,selectService,deepLinkData,selectedIssue,currentInstance)
                        },  ()=>this.onStepError(currentInstance));

                }
                }).catch(error=>{
                    /* istanbul ignore next */
                    log.warn("Failed to retrieve DeepLink data ", error);
                    currentInstance.props.history.push("/error");
                    return undefined;
                });

        return selectSymptom;

    }


    updateStep(latestRes,selectService,deepLinkData,selectedIssue,currentInstance){
        log.info("selectService Started", latestRes);
        let sys_id = selectService.serviceId;
        /* istanbul ignore else */
        if (latestRes.steps.detail.services) {
            latestRes.steps.detail.services.forEach(_service => {
                if (_service.state === "Active" && _service.service_id === sys_id) {
                    sys_id = _service.sys_id;
                }
            });
        }
        let data = {
            "type": "respond_to_step",
            "correlation_id": latestRes.correlation_id,
            "all_steps": false,
            "step":
                {
                    "id": latestRes.steps.id,
                    "activity": latestRes.steps.activity
                },
            "selected_service_sys_id": sys_id,
            "diagnostic_id": deepLinkData.data.diagnosticId
        };
        fetchWorkflowAsync(data,(data)=>{this.getAllTheSymptom(data,selectService,deepLinkData,selectedIssue,currentInstance)},
            ()=>this.errorAllTheSymptom(currentInstance),3000);
    }
    onStepError(currentInstance){
        currentInstance.props.history.push("/error");
    }
    getAllTheSymptom(res,selectService,deepLinkData,selectedIssue,currentInstance){
        if(res.steps.detail.most_likely_symptom&&res.steps.detail.most_likely_symptom.length>0){
            const symptomBody=res.steps.detail.most_likely_symptom[0];
            const all=AllSymptomList(symptomBody.display_value);

            if(selectService.technologyType === "MOBILE"){
                selectedIssue.snowSymptomName=symptomBody.display_value;
                selectedIssue.name="Mobile voice calls";
                selectedIssue.label="Mobile voice calls";
                cache.saveProperty(APP_CACHE.SELECTED_ISSUE,selectedIssue );
                let data= {
                    "type": "respond_to_step",
                    "correlation_id": res.correlation_id,
                    "all_steps": false,
                    "step":
                        {
                            "id": res.steps.id,
                            "activity": res.steps.activity
                        },
                    "selected_symptom": symptomBody.sys_id
                };

                fetchWorkflowAsync(data,(data)=>{this.saveSelectedSymptom(data,selectService,deepLinkData,selectedIssue,currentInstance)},
                    ()=>this.errorSelectedSymptom(currentInstance),3000);

            }else if(all){
                selectedIssue.snowSymptomName=all.snowSymptomName;
                selectedIssue.name=all.name;
                selectedIssue.label=all.label;
                if(selectedIssue.snowSymptomName === "Speed is slow"){
                    const diagnostic = {
                        "outageRes": "Pass",
                        "lstSerialNumber": null,
                        "nemSerialNumber": null,
                        "diagnosticRes": "Pass"
                    };
                    cache.saveProperty("diagnosticResults", diagnostic);
                }
                cache.saveProperty(APP_CACHE.SELECTED_ISSUE,selectedIssue );

                let data= {
                    "type": "respond_to_step",
                    "correlation_id": res.correlation_id,
                    "all_steps": false,
                    "step":
                        {
                            "id": res.steps.id,
                            "activity": res.steps.activity
                        },
                    "selected_symptom": symptomBody.sys_id
                };

                fetchWorkflowAsync(data,(data)=>{this.saveSelectedSymptom(data,selectService,deepLinkData,selectedIssue,currentInstance)},
                    ()=>this.errorSelectedSymptom(currentInstance),3000);

            }else{
                const {match} = currentInstance.props;
                currentInstance.onSelecteService(selectService, match);
            }
      }else{
            const {match} = currentInstance.props;
            currentInstance.onSelecteService(selectService, match);
        }

    }
    saveSelectedSymptom(data,selectService,deepLinkData,selectedIssue,currentInstance){
        cache.deleteProperty(APP_CACHE.DATA_GATHER_SUCCESS);
        cache.saveProperty(APP_CACHE.DATA_GATHER_SUCCESS,{dataGather: "SUCCESS", selectService: "SUCCESS", selectSymptom: "SUCCESS"});
         if(selectedIssue.snowSymptomName === "Wi-Fi Connection Issues"){
             currentInstance.props.history.push("/smart-troubleshooting/deeplink-symptom",{"deeplink":deepLinkData});
         }else{
             this.findRedirectUrl(selectService, selectedIssue,currentInstance);
         }

    }


    errorSelectedSymptom(currentInstance){
        currentInstance.props.history.push("/error");
    }
    errorAllTheSymptom(currentInstance){
        currentInstance.props.history.push("/error");
    }

    onIssueSelected(selectedIssue, selectedService,currentInstance){
        log.info("selected Symptom", selectedIssue);
        cache.saveProperty(APP_CACHE.SELECTED_ISSUE, selectedIssue);
        let path;
        currentInstance.state.serviceType = getServiceType(selectedService.productName);
        switch (selectedService.productName) {
            case "Mobile Service":
                path="mobile/diagnostics/help";
                break;
            case "Internet Service":
                if(selectedIssue.name === "INTERNET_SLOW"){
                    path="home-broadband/bbspeed/run-diagnostics/help";
                }else if (selectedIssue.snowSymptomName === "Wireless Connectivity"||selectedIssue.name=="WIFI_ISSUES") {
                    path="home-broadband/wifi/diagnostics/help";
                }else{
                    path="home-broadband/diagnostics/help";
                }
                break;
            case "Voice Service":
                if(selectedIssue.name === "MESSAGE_BANK"){
                    path="home-phone/messagebank";
                }else {
                    path="home-phone/diagnostics/help";
                }
                break;
            case "Applications & Entertainment Services":
                path="mailbox/diagnostics/help";
                break;
            default:
        }

        currentInstance.props.history.push(path);
        // @INFO:BrowserBackWorkflow: marking request is from select symptom (Info contact John and Komal for more detail)
        cache.saveProperty("event-run-diagnostic-initiated", "true");
    }


    findRedirectUrl(selectService, selectSymptom,currentInstance) {

        //currentInstance.props.history.push("diagnostics/help");
        this.onIssueSelected(selectSymptom,selectService,currentInstance);
        //  const mail="home-broadband/diagnostics/help";//"mailbox/diagnostics/help";
        // return mail;
    }
};
const selectDeepLinkSymptom = new SelectDeepLinkSymptom();
export default selectDeepLinkSymptom;
