import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="#0A0B09" fillRule="evenodd">
		<path d="M12.6 22.37V20h-1.2v2.37c-5.254-.302-9.468-4.516-9.77-9.77H4v-1.2H1.63c.302-5.254 4.516-9.468 9.77-9.77V4h1.2V1.63c5.254.302 9.468 4.516 9.77 9.77H20v1.2h2.37c-.302 5.254-4.516 9.468-9.77 9.77ZM12 .4C5.604.4.4 5.604.4 12S5.604 23.6 12 23.6 23.6 18.396 23.6 12 18.396.4 12 .4Z"/>
		<path d="M12.6 5h-1.2v7.248l3.711 3.711.848-.848-3.359-3.359V5"/>
	</g>
</svg>); 
 }; 
 export default SVG;