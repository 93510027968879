import React from 'react'; 

         class SVG extends React.PureComponent { 

           render = () => (<svg height="24" width="24" viewBox="0 0 25 19" xmlns="http://www.w3.org/2000/svg" {...this.props}>
	<g fill="#414141" fillRule="evenodd" transform="translate(.5)">
		<circle cx="6" cy="14" r="1"/>
		<circle cx="14" cy="14" r="1"/>
		<circle cx="18" cy="14" r="1"/>
		<circle cx="10" cy="14" r="1"/>
		<path d="M11.15 7.25h1.2a6.61 6.61 0 0 0-6.6-6.6v1.2a5.4 5.4 0 0 1 5.4 5.4Z"/>
		<path d="M8.65 7.25h1.2a4.11 4.11 0 0 0-4.1-4.1v1.2a2.9 2.9 0 0 1 2.9 2.9Z"/>
		<path d="M6.1 9.4V7H4.9v2.4H.4v9.2h23.2V9.4H6.1Zm16.3 8H1.6v-6.8h20.8v6.8Z" fillRule="nonzero"/>
	</g>
</svg>); 
 }; 
 export default SVG;